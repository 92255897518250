import { Props } from "./index.types"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Component from "./../transfers/out-for-processing"
import "./index.css"

function Example(props: Props) {
    const { message = "" } = props
    return (
        <Screen className="example-container">
            <ContainerTop
                title="Currency Reserve Vault"
                subtitle="Enter the seal number to open the vault to register any ECI transactions"
            ></ContainerTop>
            <section>{message}</section>
            <Component />
        </Screen>
    )
}

export default Example
