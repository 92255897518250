import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Card from "src/components/common/card"
import Collapse from "src/components/common/collapse"
import { useForm, Controller, useFieldArray } from "react-hook-form"
import Select from "src/components/common/select"
import { Input } from "src/components/common/input"
import Link from "src/components/common/link"
import { useState } from "react"
import Icon from "src/assets/icons"
import Button from "src/components/common/button"
import Table from "src/components/common/table"
import { data, columns } from "src/pages/vault/open/index.data"
import { useNavigate } from "react-router-dom"

function ShipmentDetail(): JSX.Element {
    const navigate = useNavigate()

    const [showAdditionalFit, setShowAdditionalFit] = useState<boolean>(false)
    const [showAdditionalNew, setShowAdditionalNew] = useState<boolean>(false)
    const fitAdditional = {
        classification: "fit",
        denomination: "",
        dollarAmount: "",
        sealNumber: "",
    }
    const newAdditional = {
        classification: "new",
        denomination: "",
        dollarAmount: "",
        sealNumber: "",
    }
    const { watch, control, getValues, setValue, reset } = useForm({
        defaultValues: {
            inventory: {
                first: {
                    classification: "fit",
                    denomination: "",
                    dollarAmount: "",
                },
                second: {
                    classification: "new",
                    denomination: "",
                    dollarAmount: "",
                },
                additional: [fitAdditional] || [newAdditional],
            },
        },
    })
    watch()
    const { fields, insert, append, remove } = useFieldArray({
        control,
        name: "inventory.additional",
    })

    function addFit() {
        const docs = getValues().inventory.additional.length
        if (docs) {
            if (getValues().inventory.additional[docs - 1].classification === "fit") {
                if (getValues().inventory.additional[docs - 1].denomination) {
                    append(fitAdditional)
                } else {
                    setShowAdditionalFit(true)
                }
            } else {
                append(fitAdditional)
                setShowAdditionalFit(true)
            }
        } else {
            insert(docs, fitAdditional)
        }
    }

    function addNew() {
        const docs = getValues().inventory.additional.length
        if (docs) {
            if (docs === 1 && !showAdditionalFit) {
                setValue("inventory.additional.0", newAdditional)
            }
            if (getValues().inventory.additional[docs - 1].classification === "new") {
                if (getValues().inventory.additional[docs - 1].denomination) {
                    append(newAdditional)
                } else {
                    setShowAdditionalNew(true)
                }
            } else {
                append(newAdditional)
                setShowAdditionalNew(true)
            }
        } else {
            insert(docs, newAdditional)
        }
    }

    const Firstfit = () => {
        const amounts = [0, 0, 0, 0, 0, 0]
        const values = getValues().inventory
        values.first.denomination === "$1" ? (amounts[5] = parseFloat(values.first.dollarAmount)) : null
        values.first.denomination === "$5" ? (amounts[4] = parseFloat(values.first.dollarAmount)) : null
        values.first.denomination === "$10" ? (amounts[3] = parseFloat(values.first.dollarAmount)) : null
        values.first.denomination === "$20" ? (amounts[2] = parseFloat(values.first.dollarAmount)) : null
        values.first.denomination === "$50" ? (amounts[1] = parseFloat(values.first.dollarAmount)) : null
        values.first.denomination === "$100" ? (amounts[0] = parseFloat(values.first.dollarAmount)) : null
        return amounts
    }

    const AdditionalFits = () => {
        const amounts = Firstfit()
        const values = getValues().inventory.additional
        values.map((additional) => {
            if (additional.classification === "fit") {
                additional.denomination === "$1" ? (amounts[5] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$5" ? (amounts[4] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$10" ? (amounts[3] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$20" ? (amounts[2] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$50" ? (amounts[1] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$100" ? (amounts[0] = parseFloat(additional.dollarAmount)) : null
            }
        })
        return amounts
    }

    const FirstNew = () => {
        const amounts = [0, 0, 0, 0, 0, 0]
        const values = getValues().inventory
        values.second.denomination === "$1" ? (amounts[5] = parseFloat(values.second.dollarAmount)) : null
        values.second.denomination === "$5" ? (amounts[4] = parseFloat(values.second.dollarAmount)) : null
        values.second.denomination === "$10" ? (amounts[3] = parseFloat(values.second.dollarAmount)) : null
        values.second.denomination === "$20" ? (amounts[2] = parseFloat(values.second.dollarAmount)) : null
        values.second.denomination === "$50" ? (amounts[1] = parseFloat(values.second.dollarAmount)) : null
        values.second.denomination === "$100" ? (amounts[0] = parseFloat(values.second.dollarAmount)) : null
        return amounts
    }

    const AdditionalNew = () => {
        const amounts = FirstNew()
        const values = getValues().inventory.additional
        values.map((additional) => {
            if (additional.classification === "new") {
                additional.denomination === "$1" ? (amounts[5] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$5" ? (amounts[4] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$10" ? (amounts[3] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$20" ? (amounts[2] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$50" ? (amounts[1] = parseFloat(additional.dollarAmount)) : null
                additional.denomination === "$100" ? (amounts[0] = parseFloat(additional.dollarAmount)) : null
            }
        })
        return amounts
    }

    const TOTAL_DATA = [
        {
            value: "$100",
            fit: AdditionalFits()[0],
            new: AdditionalNew()[0],
            total: AdditionalFits()[0] + AdditionalNew()[0],
        },
        {
            value: "$50",
            fit: AdditionalFits()[1],
            new: AdditionalNew()[1],
            total: AdditionalFits()[1] + AdditionalNew()[1],
        },
        {
            value: "$20",
            fit: AdditionalFits()[2],
            new: AdditionalNew()[2],
            total: AdditionalFits()[2] + AdditionalNew()[2],
        },
        {
            value: "$10",
            fit: AdditionalFits()[3],
            new: AdditionalNew()[3],
            total: AdditionalFits()[3] + AdditionalNew()[3],
        },
        {
            value: "$5",
            fit: AdditionalFits()[4],
            new: AdditionalNew()[4],
            total: AdditionalFits()[4] + AdditionalNew()[4],
        },
        {
            value: "$1",
            fit: AdditionalFits()[5],
            new: AdditionalNew()[5],
            total: AdditionalFits()[5] + AdditionalNew()[5],
        },
    ]

    const totalFits = () => {
        return (
            AdditionalFits()[0] +
            AdditionalFits()[1] +
            AdditionalFits()[2] +
            AdditionalFits()[3] +
            AdditionalFits()[4] +
            AdditionalFits()[5]
        )
    }

    const totalNews = () => {
        return (
            AdditionalNew()[0] +
            AdditionalNew()[1] +
            AdditionalNew()[2] +
            AdditionalNew()[3] +
            AdditionalNew()[4] +
            AdditionalNew()[5]
        )
    }

    function onSubmit() {
        console.log("values", getValues())
    }

    function setButton() {
        if (Number.isNaN(totalFits() + totalNews()) || totalFits() + totalNews() === 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <Screen style={{ overflowX: "visible", background: "white" }}>
            <ContainerTop
                style={{ height: "180px" }}
                onBack={() => navigate("/shipment/in")}
                back={true}
                className="shipment_detail__header"
            >
                <div className="shipment_detail__header__container">
                    <div>
                        <span className="shipment_detail__header__container__title">Shipment ID: 91827364</span>
                        <div className="shipment_detail__header__container__values">
                            <span className="shipment_detail__header__container__values--label">
                                Total Dollar Amount:
                            </span>
                            <span className="shipment_detail__header__container__values--value">$999,999,999</span>
                        </div>
                    </div>
                    <div className="shipment_detail__header__container--right">
                        <div className="shipment_detail__header__container__values">
                            <span className="shipment_detail__header__container__values--label">Date Shipped:</span>
                            <span className="shipment_detail__header__container__values--value">
                                October 14, 2022 16:23:45
                            </span>
                        </div>
                        <div className="shipment_detail__header__container__values">
                            <span className="shipment_detail__header__container__values--label">Date recieved:</span>
                            <span className="shipment_detail__header__container__values--value">
                                October 14, 2022 16:23:45
                            </span>
                        </div>
                        <div className="shipment_detail__header__container__values">
                            <span className="shipment_detail__header__container__values--label">Shiped from: </span>
                            <span className="shipment_detail__header__container__values--value">FRB - FRBNY</span>
                        </div>
                    </div>
                </div>
            </ContainerTop>
            <section className="shipments_detail">
                <div className="shipments_detail--left">
                    <Card className="shipments_detail__card">
                        <div className="shipments_detail__card--left">
                            <span className="shipments_detail__card--title">Partial shipment details</span>
                            <span className="shipments_detail__card--subtitle">Date Time Recieved</span>
                            <span className="shipments_detail__card--value">September 14, 2022 12:23:45</span>
                            <span className="shipments_detail__card--value">October 12, 2022 16:23:45</span>
                        </div>
                        <div className="shipments_detail__card--right">
                            <span className="shipments_detail__card--subtitle">Total Amount Recieved</span>
                            <span className="shipments_detail__card--value">$999,999,999</span>
                            <span className="shipments_detail__card--value">$999,999,999</span>
                        </div>
                    </Card>
                    <Collapse title="Fit" className="shipments_detail__collapse" defaultVisible={true}>
                        <div className="shipments_detail__collapse__container">
                            <div className="shipments_detail__collapse__container__column">
                                <span className="shipments_detail__collapse__container__column__title">
                                    Denomination
                                </span>
                                <Controller
                                    name="inventory.first.denomination"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            className="shipments_detail__collapse__container__column__input"
                                            placeholder="Denomination"
                                            icon="denomination"
                                            options={[
                                                { label: "$1", value: "$1" },
                                                { label: "$5", value: "$5" },
                                                { label: "$20", value: "$20" },
                                                { label: "$50", value: "$50" },
                                                { label: "$100", value: "$100" },
                                            ]}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="shipments_detail__collapse__container__column">
                                <span className="shipments_detail__collapse__container__column__title">
                                    Dollar amount
                                </span>
                                <Controller
                                    name="inventory.first.dollarAmount"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            className="shipments_detail__collapse__container__column__input"
                                            placeholder="Dollar amount"
                                            icon="dollar"
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {fields.map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className={
                                        showAdditionalFit
                                            ? item.classification === "new"
                                                ? "shipments_detail__collapse__container--add"
                                                : "shipments_detail__collapse__container"
                                            : "shipments_detail__collapse__container--add"
                                    }
                                >
                                    <div className="shipments_detail__collapse__container__column">
                                        <span className="shipments_detail__collapse__container__column__title">
                                            Denomination
                                        </span>
                                        <Controller
                                            name={`inventory.additional.${index}.denomination`}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    className="shipments_detail__collapse__container__column__input"
                                                    placeholder="Denomination"
                                                    icon="denomination"
                                                    options={[
                                                        { label: "$1", value: "$1" },
                                                        { label: "$5", value: "$5" },
                                                        { label: "$20", value: "$20" },
                                                        { label: "$50", value: "$50" },
                                                        { label: "$100", value: "$100" },
                                                    ]}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="shipments_detail__collapse__container__column">
                                        <span className="shipments_detail__collapse__container__column__title">
                                            Dollar amount
                                        </span>
                                        <Controller
                                            name={`inventory.additional.${index}.dollarAmount`}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    className="shipments_detail__collapse__container__column__input"
                                                    placeholder="Dollar amount"
                                                    icon="dollar"
                                                    type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <Icon
                                        name={"trashcan"}
                                        onClick={() => remove(index)}
                                        className="shipments_detail__collapse__container__trashcan"
                                    />
                                </div>
                            )
                        })}
                        <Link
                            icon="add"
                            text="Add denomination"
                            onClick={() => addFit()}
                            className="shipments_detail__collapse__container__add"
                        />
                    </Collapse>
                    <Collapse title="New" className="shipments_detail__collapse" defaultVisible={true}>
                        <div className="shipments_detail__collapse__container">
                            <div className="shipments_detail__collapse__container__column">
                                <span className="shipments_detail__collapse__container__column__title">
                                    Denomination
                                </span>
                                <Controller
                                    name="inventory.second.denomination"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            className="shipments_detail__collapse__container__column__input"
                                            placeholder="Denomination"
                                            icon="denomination"
                                            options={[
                                                { label: "$1", value: "$1" },
                                                { label: "$5", value: "$5" },
                                                { label: "$20", value: "$20" },
                                                { label: "$50", value: "$50" },
                                                { label: "$100", value: "$100" },
                                            ]}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="shipments_detail__collapse__container__column">
                                <span className="shipments_detail__collapse__container__column__title">
                                    Dollar amount
                                </span>
                                <Controller
                                    name="inventory.second.dollarAmount"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            className="shipments_detail__collapse__container__column__input"
                                            placeholder="Dollar amount"
                                            icon="dollar"
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        {fields.map((item, index) => {
                            return (
                                <div
                                    key={item.id}
                                    className={
                                        showAdditionalNew
                                            ? item.classification === "fit"
                                                ? index === 0
                                                    ? "shipments_detail__collapse__container"
                                                    : "shipments_detail__collapse__container--add"
                                                : "shipments_detail__collapse__container"
                                            : "shipments_detail__collapse__container--add"
                                    }
                                >
                                    <div className="shipments_detail__collapse__container__column">
                                        <span className="shipments_detail__collapse__container__column__title">
                                            Denomination
                                        </span>
                                        <Controller
                                            name={`inventory.additional.${index}.denomination`}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    className="shipments_detail__collapse__container__column__input"
                                                    placeholder="Denomination"
                                                    icon="denomination"
                                                    options={[
                                                        { label: "$1", value: "$1" },
                                                        { label: "$2", value: "$2" },
                                                        { label: "$5", value: "$5" },
                                                        { label: "$20", value: "$20" },
                                                        { label: "$50", value: "$50" },
                                                        { label: "$100", value: "$100" },
                                                    ]}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="shipments_detail__collapse__container__column">
                                        <span className="shipments_detail__collapse__container__column__title">
                                            Dollar amount
                                        </span>
                                        <Controller
                                            name={`inventory.additional.${index}.dollarAmount`}
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    className="shipments_detail__collapse__container__column__input"
                                                    placeholder="Dollar amount"
                                                    icon="dollar"
                                                    type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <Icon
                                        name={"trashcan"}
                                        onClick={() => remove(index)}
                                        className="shipments_detail__collapse__container__trashcan"
                                    />
                                </div>
                            )
                        })}
                        <Link
                            icon="add"
                            text="Add denomination"
                            onClick={() => addNew()}
                            className="shipments_detail__collapse__container__add"
                        />
                    </Collapse>
                </div>
                <Card className="shipments_detail--right">
                    <span className="shipments_detail__card--title">Shipment In for ECI Inventory</span>
                    <div className="shipments_detail__table">
                        <div className="shipments_detail__table__data">
                            <div className="shipments_detail__table__data__column"></div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title">Fit</span>
                            </div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title">New</span>
                            </div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title--green">Total</span>
                            </div>
                        </div>
                        {TOTAL_DATA.map((value, index) => (
                            <div className="shipments_detail__table__data" key={index}>
                                <div className="shipments_detail__table__data__column--titles">
                                    <span className="shipments_detail__table__data__column__title">{value.value}</span>
                                </div>
                                <div className="shipments_detail__table__data__column">
                                    <span className="shipments_detail__table__data__column__title--value">
                                        ${Number.isNaN(value.fit) ? 0 : value.fit}
                                    </span>
                                </div>
                                <div className="shipments_detail__table__data__column">
                                    <span className="shipments_detail__table__data__column__title--value">
                                        ${Number.isNaN(value.new) ? 0 : value.new}
                                    </span>
                                </div>
                                <div className="shipments_detail__table__data__column">
                                    <span className="shipments_detail__table__data__column__title--value">
                                        ${Number.isNaN(value.total) ? 0 : value.total}
                                    </span>
                                </div>
                            </div>
                        ))}
                        <div className="shipments_detail__table__data">
                            <div className="shipments_detail__table__data__column--titles">
                                <span className="shipments_detail__table__data__column__title--green">Total</span>
                            </div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title">
                                    ${Number.isNaN(totalFits()) ? 0 : totalFits()}
                                </span>
                            </div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title">
                                    ${Number.isNaN(totalNews()) ? 0 : totalNews()}
                                </span>
                            </div>
                            <div className="shipments_detail__table__data__column">
                                <span className="shipments_detail__table__data__column__title">
                                    ${Number.isNaN(totalNews() + totalFits()) ? 0 : totalNews() + totalFits()}
                                </span>
                            </div>
                        </div>
                        <Button
                            disabled={setButton()}
                            onClick={() => onSubmit()}
                            style={{ width: "100%", alignSelf: "center", marginTop: "40px" }}
                        >
                            Register ${Number.isNaN(totalNews() + totalFits()) ? 0 : totalNews() + totalFits()}
                        </Button>
                        <div className="shipments_out__columns__link">
                            <Link
                                text="Reset"
                                onClick={() => {
                                    reset()
                                    setShowAdditionalFit(false)
                                    setShowAdditionalNew(false)
                                }}
                            />
                        </div>
                    </div>
                </Card>
            </section>
            <Card className="shipments_detail__card__table__container">
                <Table
                    data={data}
                    columns={columns}
                    title="Vault inventory as for October 4, 2022"
                    border={true}
                    editable={false}
                    containerClassName="shipments_detail__card__table"
                />
            </Card>
        </Screen>
    )
}

export default ShipmentDetail
