import cx from "classnames"
import { ContainerTopProps } from "./index.type"
import "./index.css"
import Icon from "src/assets/icons"

function ContainerTop(props: ContainerTopProps): JSX.Element {
    const { className = "", children, style = {}, title = "", subtitle = "", back = false, onBack = () => null } = props
    return (
        <section className={cx("container-top", className)} style={style}>
            {back && (
                <div className="container-top__back" onClick={onBack}>
                    <Icon
                        name="chevronRight"
                        style={{ transform: "rotate(180deg)" }}
                        className="container-top__icon-back"
                    />
                    <p className="container-top__text-back">Back</p>
                </div>
            )}
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
            {children}
        </section>
    )
}

export default ContainerTop
