import { useState, useEffect } from "react"
import { getMoneyFormat } from "src/utils/string"
import { Input } from "../input"
import { CurrencyInputProps } from "./index.types"

function CurrencyInput({
    className = "",
    style = {},
    placeholder = "$0",
    icon = "",
    value = 0,
    error = false,
    onChange,
}: CurrencyInputProps): JSX.Element {
    const [lvalue, setValue] = useState<string>("")

    useEffect(() => {
        if (value > 0) {
            let moneyFormat = getMoneyFormat(value)
            if (lvalue !== moneyFormat) {
                setValue(moneyFormat)
            }
        } else {
            setValue("")
        }
    }, [value])

    const handleChange = (value: string): void => {
        if (value) {
            setValue(value.replace(/\D/g, ""))
        } else {
            setValue("")
        }
    }

    const handleBlur = (value: string): void => {
        if (value) {
            let decimal = parseFloat(value)
            let moneyFormat = getMoneyFormat(decimal)
            onChange(decimal)
            setValue(moneyFormat)
        }
    }

    return (
        <Input
            className={className}
            placeholder={placeholder}
            style={style}
            error={error}
            icon={icon}
            value={lvalue}
            onChange={handleChange}
            onBlur={(e) => handleBlur(e.target.value)}
            onFocus={(e) => handleChange(e.target.value)}
        />
    )
}

export default CurrencyInput
