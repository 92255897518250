import cx from "classnames"
import { ScreenProps } from "./index.type"
import "./index.css"

function Screen(props: ScreenProps): JSX.Element {
    const { className = "", children, style = {} } = props

    return (
        <section className={cx("screen__container", className)} style={style}>
            {children}
        </section>
    )
}

export default Screen
