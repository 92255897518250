import { memo, SVGProps } from "react"

const RegionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.375 17.425c-.025 0-.058.017-.083.017A8.338 8.338 0 0 1 2.55 13.7c0-.024.017-.058.017-.083 1.016.3 2.066.525 3.108.7.183 1.05.4 2.092.7 3.108ZM17.45 13.709c-.825 1.658-2.2 3-3.875 3.808.317-1.058.583-2.125.758-3.2 1.05-.175 2.084-.4 3.1-.7-.008.033.017.067.017.092ZM17.517 6.424a26.368 26.368 0 0 0-3.184-.758 23.262 23.262 0 0 0-.758-3.184 8.376 8.376 0 0 1 3.942 3.942ZM6.375 2.576a26.04 26.04 0 0 0-.692 3.1 24.058 24.058 0 0 0-3.2.75A8.411 8.411 0 0 1 6.292 2.55c.025 0 .058.025.083.025ZM12.908 5.491a26.106 26.106 0 0 0-5.816 0c.208-1.142.475-2.283.85-3.383.016-.067.008-.117.016-.184A8.672 8.672 0 0 1 10 1.666c.7 0 1.383.1 2.033.258.009.067.009.117.025.184.375 1.108.642 2.241.85 3.383ZM5.492 12.909c-1.15-.209-2.284-.476-3.384-.85-.066-.017-.116-.009-.183-.017A8.671 8.671 0 0 1 1.667 10c0-.7.1-1.383.258-2.033.067-.009.117-.009.183-.025a24.808 24.808 0 0 1 3.384-.85 27.144 27.144 0 0 0 0 5.817ZM18.333 10c0 .709-.1 1.383-.258 2.042-.067.008-.117 0-.183.017a25.136 25.136 0 0 1-3.384.85 26.103 26.103 0 0 0 0-5.817c1.142.208 2.284.475 3.384.85a.695.695 0 0 0 .183.025c.158.658.258 1.333.258 2.033ZM12.908 14.508c-.208 1.15-.475 2.283-.85 3.383-.016.067-.016.117-.025.184a8.603 8.603 0 0 1-2.033.258c-.708 0-1.383-.1-2.042-.258-.008-.067 0-.117-.016-.184a24.809 24.809 0 0 1-.85-3.383c.966.108 1.933.183 2.908.183.975 0 1.95-.075 2.908-.183ZM13.136 13.136a25.04 25.04 0 0 1-6.272 0 25.033 25.033 0 0 1 0-6.273 25.032 25.032 0 0 1 6.272 0 25.04 25.04 0 0 1 0 6.272Z"
            fill="#247346"
        />
    </svg>
)
const RegionIconMemo = memo(RegionIcon)

export default RegionIconMemo
