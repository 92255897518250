import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Card from "src/components/common/card"
import Icon from "src/assets/icons"
import { dataCards } from "./index.data"
import { useNavigate } from "react-router-dom"

function ShipmentIn(): JSX.Element {
    const navigate = useNavigate()
    return (
        <Screen>
            <ContainerTop
                style={{ height: "160px" }}
                onBack={() => navigate("/shipment")}
                back={true}
                title="Shipment In"
                subtitle="Recieve Shipments In to the ECI Inventory in valid bag increment amounts."
            />
            <section className="shipments_in">
                <span className="shipments_in__title">Shipments In for October 4, 2022</span>
                {dataCards.map((data, index) => (
                    <Card key={index} className="shipments_in__card">
                        <span className="shipments_in__card__title">Shipment ID: {data.id}</span>
                        <div className="shipments_in__card__columns">
                            {data.info.map((info, index) => (
                                <div className="shipments_in__card__columns__item" key={index}>
                                    <span>{info.label}</span>
                                    <span>{info.value}</span>
                                </div>
                            ))}
                            <Icon
                                name="chevronRightGreen"
                                onClick={() => navigate("/shipment/detail")}
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    </Card>
                ))}
            </section>
        </Screen>
    )
}

export default ShipmentIn
