import { memo, SVGProps } from "react"

const TruckOut = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={46} height={46} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={23} cy={23} r={23} fill="#48B050" fillOpacity={0.15} />
            <path
                d="M26.333 12.406v9.695c0 1.19-.968 2.159-2.158 2.159H13.5a1.17 1.17 0 0 1-1.167-1.167v-7.455a4.302 4.302 0 0 1 4.305-4.305h8.61c.607 0 1.085.478 1.085 1.073Z"
                fill="#287248"
            />
            <path
                d="M35.083 27.083c.327 0 .584.257.584.583v1.167c0 1.937-1.564 3.5-3.5 3.5a3.51 3.51 0 0 0-3.5-3.5 3.51 3.51 0 0 0-3.5 3.5h-2.334a3.51 3.51 0 0 0-3.5-3.5 3.51 3.51 0 0 0-3.5 3.5 3.495 3.495 0 0 1-3.5-3.5V26.5a1.17 1.17 0 0 1 1.167-1.167h11.083a2.918 2.918 0 0 0 2.917-2.917V16a1.17 1.17 0 0 1 1.167-1.167h.98c.84 0 1.61.455 2.03 1.178l.746 1.307a.294.294 0 0 1-.256.432 2.918 2.918 0 0 0-2.917 2.916v3.5a2.918 2.918 0 0 0 2.917 2.917h2.916Z"
                fill="#287248"
            />
            <path
                d="M19.333 34.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM28.667 34.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM35.667 23.618v1.715h-3.5A1.17 1.17 0 0 1 31 24.167v-3.5a1.17 1.17 0 0 1 1.167-1.167h1.505l1.691 2.963c.199.35.304.747.304 1.155Z"
                fill="#287248"
            />
            <path
                d="M20.254 20.665a.265.265 0 0 1-.2-.094.353.353 0 0 1 0-.453l2.096-2.37-2.096-2.37a.353.353 0 0 1 0-.453.263.263 0 0 1 .4 0l2.298 2.596c.11.124.11.33 0 .454l-2.297 2.596a.266.266 0 0 1-.201.094Z"
                fill="#E4F3E5"
            />
            <path
                d="M22.487 18.069h-6.37c-.154 0-.283-.145-.283-.32 0-.176.129-.322.284-.322h6.369c.155 0 .284.146.284.321 0 .175-.13.321-.284.321Z"
                fill="#E4F3E5"
            />
        </svg>
    )
})

export default TruckOut
