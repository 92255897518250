import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import CardTransfer from "src/components/sections/transfers/card"

function Transfers(): JSX.Element {
    return (
        <Screen>
            <ContainerTop title="Transfers" subtitle="Recieve and deliver Banknotes in and out the ECI inventory" />
            <section className="transfers-container-main">
                <CardTransfer
                    title="Transfers for Prepak"
                    text="Enter preparation information in the Shipment Details when preparing a Shipment Out."
                    icon="moneyBag"
                    route="/transfers/transfers-prepak/prepak-in"
                />
                <CardTransfer
                    title="Transfers for Processing"
                    text="Processed Banknotes for the ECI´s inventory to be delivered to the NY Fed´s cash operation."
                    icon="truck"
                    route="/transfers/transfers-processing/in"
                />
            </section>
        </Screen>
    )
}

export default Transfers
