import React from "react"
import Router from "./pages"
import ReactDOM from "react-dom/client"
import "normalize.css"
import "./index.css"

import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <React.StrictMode>
        <Router />
    </React.StrictMode>
)

reportWebVitals()
