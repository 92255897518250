import { DefaultValue } from "./index.types"

export const itemField = {
    name: "",
    key: "",
    value: 0,
}

export const defaultValues: DefaultValue = {
    values: [itemField],
}

export const keys = ["$100", "$50", "$20", "$10", "$5", "$1"]

export const options = keys.map((key) => {
    return { label: key, value: key }
})

export const columns = [
    { header: "", accessor: "name", className: "out-processing__table__col_1" },
    { header: "Unprocessed", accessor: "amount", bold: false, className: "out-processing__table__col_2" },
    { header: "Total", accessor: "total", bold: false },
]
