import { memo, SVGProps } from "react"

const ChevronRightGreen = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={14} height={26} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m13.263 13.442-.91 1.064L2.5 26l-2.502-2.13 8.942-10.428L-.001 3.013 2.501.883l9.851 11.494.912 1.065Z"
                fill="#247346"
            />
        </svg>
    )
})

export default ChevronRightGreen
