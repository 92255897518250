export const drawerItems = [
    { label: "Vault", path: "/vault", icon: "strongBox" },
    { label: "Country flow", path: "/country", icon: "globalRefresh" },
    { label: "Denomination switch", path: "/denomination", icon: "coin" },
    { label: "Deposits", path: "/deposits", icon: "moneyRecive" },
    { label: "Redeposits", path: "/redeposits", icon: "moneyChange" },
    { label: "Payments", path: "/payments", icon: "moneySend" },
    { label: "Shipment", path: "/shipment", icon: "boldTruck" },
    { label: "Transfers", path: "/transfers", icon: "cubeRotate" },
]
