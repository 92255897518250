import { memo, SVGProps } from "react"

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={31} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.422 4.964v-1.95a.944.944 0 0 0-.938-.938.944.944 0 0 0-.937.938v1.875h-8.125V3.014a.944.944 0 0 0-.938-.938.944.944 0 0 0-.937.938v1.95c-3.375.312-5.012 2.325-5.262 5.312a.628.628 0 0 0 .625.663h21.15c.362 0 .662-.313.625-.663-.25-2.987-1.888-5-5.263-5.312ZM25.485 12.813h-20c-.688 0-1.25.562-1.25 1.25v7.7c0 3.75 1.875 6.25 6.25 6.25h10c4.375 0 6.25-2.5 6.25-6.25v-7.7c0-.688-.563-1.25-1.25-1.25ZM20.06 24.925a.927.927 0 0 1-.663.275.927.927 0 0 1-.662-.275L17.81 24a4.63 4.63 0 0 1-2.538.762 4.63 4.63 0 0 1-4.625-4.625 4.63 4.63 0 0 1 4.625-4.625 4.63 4.63 0 0 1 4.625 4.625 4.63 4.63 0 0 1-.762 2.538l.925.925a.943.943 0 0 1 0 1.325Z"
            fill="#308B57"
        />
    </svg>
)
const CalendarIconMemo = memo(CalendarIcon)

export default CalendarIconMemo
