import cx from "classnames"
import { getMoneyFormat } from "src/utils/string"
import CurrencyInput from "./../input/currency"
import { Props } from "./index.types"
import "./index.css"

function Table({
    className = "",
    containerClassName = "",
    style = {},
    title = "",
    border = true,
    columns = [],
    data = [],
    editable = false,
    moneyFormat = true,
    rowHeader = true,
    showTotal = true,
    editableConfig = {
        minRow: 0,
        maxRow: data.length - 1,
        minCol: 1,
        maxCol: columns.length - 1,
    },
    onChange = undefined,
}: Props): JSX.Element {
    return (
        <div className={cx("table__container", containerClassName)} style={style}>
            {title && <h6 className="table__title">{title}</h6>}
            <div className="table__box">
                <table className={cx({ table: true, table__border: border, [className]: className })}>
                    <thead>
                        <tr>
                            {columns.map(({ header = "", accessor = "" }) => (
                                <th className="table__th" key={accessor}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {columns.map(({ accessor = "", bold = true, className = "" }, key) => (
                                    <td
                                        className={cx({
                                            table__td: true,
                                            table__td__bold: bold,
                                            table__td__total: index === data.length - 1 && key === 0 && rowHeader,
                                            [className]: className,
                                        })}
                                        key={key}
                                    >
                                        {editable &&
                                        index >= editableConfig.minRow &&
                                        index < editableConfig.maxRow &&
                                        key >= editableConfig.minCol &&
                                        key < editableConfig.maxCol ? (
                                            <CurrencyInput
                                                className="table__td__input"
                                                placeholder="$0"
                                                value={item[accessor]}
                                                onChange={(value: number) => {
                                                    if (onChange) {
                                                        onChange({
                                                            value,
                                                            key: accessor,
                                                            index,
                                                        })
                                                    }
                                                }}
                                            />
                                        ) : item[accessor] !== undefined ? (
                                            key === 0 ? (
                                                rowHeader ? (
                                                    item[accessor]
                                                ) : (
                                                    getMoneyFormat(item[accessor])
                                                )
                                            ) : index === data.length - 1 &&
                                              key === columns.length - 1 &&
                                              !showTotal ? (
                                                ""
                                            ) : moneyFormat ? (
                                                getMoneyFormat(item[accessor])
                                            ) : (
                                                item[accessor]
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Table
