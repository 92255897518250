import { memo, SVGProps } from "react"

const CoinIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.92 16.75c-.33 2.66-2.51 4.84-5.17 5.17-1.61.2-3.11-.24-4.28-1.1-.67-.49-.51-1.53.29-1.77a9.486 9.486 0 0 0 6.3-6.3c.24-.79 1.28-.95 1.77-.29a6.02 6.02 0 0 1 1.09 4.29Z"
            fill="#fff"
        />
        <path
            d="M9.99 2C5.58 2 2 5.58 2 9.99s3.58 7.99 7.99 7.99 7.99-3.58 7.99-7.99C17.97 5.58 14.4 2 9.99 2Zm-.94 6.87 2.41.84c.87.31 1.29.92 1.29 1.86 0 1.08-.86 1.97-1.91 1.97h-.09v.05c0 .41-.34.75-.75.75s-.75-.34-.75-.75v-.06c-1.11-.05-2-.98-2-2.14 0-.41.34-.75.75-.75s.75.34.75.75c0 .36.26.65.58.65h1.5c.23 0 .41-.21.41-.47 0-.35-.06-.37-.29-.45l-2.41-.84c-.86-.3-1.29-.91-1.29-1.86 0-1.08.86-1.97 1.91-1.97h.09v-.04c0-.41.34-.75.75-.75s.75.34.75.75v.06c1.11.05 2 .98 2 2.14 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.36-.26-.65-.58-.65h-1.5c-.23 0-.41.21-.41.47-.01.34.05.36.29.44Z"
            fill="#fff"
        />
    </svg>
)

const CoinIconMemo = memo(CoinIcon)

export default CoinIconMemo
