import { memo, SVGProps } from "react"

const DollarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10.625 13.268h.542c.541 0 .991-.484.991-1.067 0-.725-.258-.867-.683-1.017l-.842-.291v2.375h-.008Z"
            fill="#247346"
        />
        <path
            d="M9.975 1.584c-4.6.017-8.325 3.758-8.308 8.358.016 4.6 3.758 8.325 8.358 8.309 4.6-.017 8.325-3.759 8.308-8.359-.016-4.6-3.758-8.316-8.358-8.308Zm1.908 8.417c.65.225 1.525.708 1.525 2.2 0 1.283-1.008 2.316-2.241 2.316h-.542v.484a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625v-.484h-.3c-1.367 0-2.475-1.15-2.475-2.566a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625c0 .725.55 1.316 1.225 1.316h.3v-2.816L8.117 10c-.65-.225-1.525-.709-1.525-2.2 0-1.284 1.008-2.317 2.241-2.317h.542v-.483A.63.63 0 0 1 10 4.376a.63.63 0 0 1 .625.625v.483h.3c1.367 0 2.475 1.15 2.475 2.567a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625c0-.725-.55-1.317-1.225-1.317h-.3v2.817l1.258.45Z"
            fill="#247346"
        />
        <path
            d="M7.85 7.807c0 .725.258.867.683 1.017l.842.292V6.732h-.542c-.541 0-.983.484-.983 1.075Z"
            fill="#247346"
        />
    </svg>
)
const DollarIconMemo = memo(DollarIcon)

export default DollarIconMemo
