import { useState } from "react"
import Collapse from "src/components/common/collapse"
import Table, { getDynamicData } from "src/components/common/table"
import ContainerTop from "src/components/layout/containerTop"
import Screen from "src/components/layout/screen"
import { columns, dataTableFit, dataTableNew, dataTableProcessed, dataTableUnprocessed, dataCollapse } from "./data"
import Icon from "src/assets/icons"
import TextArea from "src/components/common/textArea"
import "./index.css"
import Button from "src/components/common/button"
import Link from "src/components/common/link"

function DenominationSwitch(): JSX.Element {
    const dataFitNew = JSON.parse(JSON.stringify(dataTableFit))
    const [dataFit, setDataFit] = useState<any[]>(dataFitNew)

    const dataTabNew = JSON.parse(JSON.stringify(dataTableNew))
    const [dataNew, setDataNew] = useState<any[]>(dataTabNew)

    const dataProcessNew = JSON.parse(JSON.stringify(dataTableProcessed))
    const [dataProcessed, setDataProcessed] = useState<any[]>(dataProcessNew)

    const dataUnprocessNew = JSON.parse(JSON.stringify(dataTableUnprocessed))
    const [dataUnprocessed, setDataUnprocessed] = useState<any[]>(dataUnprocessNew)

    const [comment, setComment] = useState<string>("")
    const [toggle, setToggle] = useState(true)

    function getDataTable(nameData: string) {
        switch (nameData) {
            case "fit":
                return dataFit
                break
            case "new":
                return dataNew
                break
            case "processed":
                return dataProcessed
                break
            case "unprocessed":
                return dataUnprocessed
                break
            default:
                return dataFit
        }
    }
    function handleChangeData(index: number, key: string, value: number, item: string) {
        if (item === "fit") {
            let picker: any[] = [...dataFit]
            picker[index][key] = value
            let dynamic = getDynamicData(picker, "total")
            setDataFit([...dynamic])
        } else if (item === "new") {
            let picker: any[] = [...dataNew]
            picker[index][key] = value
            let dynamic = getDynamicData(picker, "total")
            setDataNew([...dynamic])
        } else if (item === "processed") {
            let picker: any[] = [...dataProcessed]
            picker[index][key] = value
            let dynamic = getDynamicData(picker, "total")
            setDataProcessed([...dynamic])
        } else if (item === "unprocessed") {
            let picker: any[] = [...dataUnprocessed]
            picker[index][key] = value
            let dynamic = getDynamicData(picker, "total")
            setDataUnprocessed([...dynamic])
        }
    }

    function handleReset() {
        setDataFit([...dataTableFit])
        setDataNew([...dataTableNew])
        setDataProcessed([...dataTableProcessed])
        setDataUnprocessed([...dataTableUnprocessed])
        setComment("")
    }

    return (
        <Screen>
            <ContainerTop
                title="Denomination Switch"
                subtitle="Correct denomination or classification type errors in the ECI inventory."
            >
                <div>
                    <p>Denomination switches must net $0</p>
                    <p className="denomination-switch__text-money">$0</p>
                </div>
            </ContainerTop>
            <section className="denomination-switch__main">
                <div className="denomination-switch__container-toggle">
                    <Link text="Toggle all" onClick={() => setToggle(!toggle)} />
                </div>

                {dataCollapse.map((item, key) => (
                    <Collapse
                        title={item.name}
                        className="denomination-switch__collapse"
                        key={key}
                        defaultVisible={toggle}
                    >
                        <Table
                            data={getDataTable(item.data)}
                            onChange={({ index, key, value }) => handleChangeData(index, key, value, item.data)}
                            border={false}
                            editable={true}
                            columns={columns}
                            editableConfig={{
                                minRow: 1,
                                maxRow: 2,
                                minCol: 1,
                                maxCol: columns.length - 1,
                            }}
                        />
                    </Collapse>
                ))}
                <div className="denomination-switch__container-comment">
                    <div className="denomination-switch__container-comment__title">
                        <Icon name="message" /> <h4>Comment</h4>
                    </div>
                    <div>
                        <TextArea
                            placeholder="Enter a comment"
                            value={comment}
                            onChange={(value) => setComment(value)}
                        />
                    </div>
                </div>
                <div className="denomination-switch__footer">
                    <Button className="denomination-switch__footer__button">Submit</Button>
                    <Link onClick={handleReset} text="Reset" className="denomination-switch__footer__link" />
                </div>
            </section>
        </Screen>
    )
}

export default DenominationSwitch
