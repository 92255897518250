import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Tabs from "src/components/common/tabs"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { items } from "./index.data"

function TransfersProcessing(): JSX.Element {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    return (
        <Screen>
            <ContainerTop
                title="Transfers for Processing"
                subtitle="Recieve and deliver Banknotes in and out the ECI inventory"
                back={true}
                className="transfers-processing__container-top"
                onBack={() => navigate("/transfers")}
            >
                <Tabs
                    className="transfers-processing__tabs"
                    items={items}
                    current={pathname}
                    onChange={(value) => navigate(value)}
                />
            </ContainerTop>
            <section>
                <Outlet />
            </section>
        </Screen>
    )
}

export default TransfersProcessing
