import { Props } from "./index.types"
import Card from "src/components/common/card"
import cx from "classnames"
import "./index.css"
import Icon from "src/assets/icons"
import { Link } from "react-router-dom"

function CardTransfer(props: Props) {
    const { className = "", title = "", text = "", icon = "", style = {}, route = "" } = props
    return (
        <Card className={cx("transfer-card__container-main", className)} style={style}>
            <div className="transfer-card__container-left">
                <div className="transfer-card__icon">
                    <Icon name={icon} />
                </div>
                <h4 className="transfer-card__title">{title}</h4>
                <p className="transfer-card__text">{text}</p>
            </div>
            <div className="transfer-card__container-right">
                <Link to={route}>
                    <Icon name="arrowRight" />
                </Link>
            </div>
        </Card>
    )
}

export default CardTransfer
