import { memo, SVGProps } from "react"

const ArrowRight = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={20} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.43 13.82c-.19 0-.38-.07-.53-.22a.754.754 0 0 1 0-1.06L17.44 7 11.9 1.46a.754.754 0 0 1 0-1.06c.29-.29.77-.29 1.06 0l6.07 6.07c.29.29.29.77 0 1.06l-6.07 6.07c-.15.15-.34.22-.53.22Z"
                fill="#287248"
            />
            <path
                d="M18.33 7.75H1.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h16.83c.41 0 .75.34.75.75s-.34.75-.75.75Z"
                fill="#287248"
            />
        </svg>
    )
})

export default ArrowRight
