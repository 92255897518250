import { memo, SVGProps } from "react"

const ChevronRight = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1em" height="1em" fill="none" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m6.848 5.915-.43.501-4.64 5.414L.6 10.827l4.212-4.912L.6 1.003 1.778 0l4.64 5.414.43.501Z"
                fill="#F0F0F0"
            />
        </svg>
    )
})

export default ChevronRight
