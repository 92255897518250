import Layout from "src/components/sections/payment"
import { getInventoryData, getData } from "src/components/sections/payment/index.data"

const labels = ["Fit", "New", "Processed", "Unprocessed", "Total"]

function Payment(): JSX.Element {
    return (
        <Layout
            title="Redeposits"
            subtitle="Enter the Redeposits back into the ECI Inventory in full bundle increments. Only full bundles of Banknotes may be placed into the ECI inventory"
            tableTitle="Enter redeposits to Inventory for October 4, 2022"
            buttonText="Redeposits"
            data={getData(labels)}
            dataInventory={getInventoryData(labels)}
        />
    )
}

export default Payment
