import { memo, SVGProps } from "react"

const ReserveOfficeIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.632 12.809v1.825c0 2.033-1.483 3.95-3.5 4.508a.916.916 0 0 1-.425 0 4.734 4.734 0 0 1-2.458-1.642c-.659-.816-1.042-1.825-1.042-2.866v-1.825c0-.35.258-.733.575-.867l2.317-.95a2.213 2.213 0 0 1 1.633 0l.833.342 1.492.608c.317.133.575.517.575.867Z"
            fill="#247346"
        />
        <path
            d="m17.924 9.175-.15.892-.584-.233a3.419 3.419 0 0 0-2.566 0l-2.325.958a2.237 2.237 0 0 0-1.342 2.017v1.825c0 .991.275 1.983.783 2.866H5.615c-1.141 0-2.216-.908-2.408-2.033l-1.05-6.292c-.133-.775.258-1.816.875-2.308l5.483-4.383c.842-.675 2.209-.675 3.05.008l5.484 4.375c.608.492 1.008 1.533.875 2.308Z"
            fill="#247346"
        />
    </svg>
)
const ReserveOfficeIconMemo = memo(ReserveOfficeIcon)

export default ReserveOfficeIconMemo
