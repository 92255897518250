import Layout from "src/components/sections/payment"
import { getInventoryData, getData } from "src/components/sections/payment/index.data"

const list = ["Fit", "New", "Total"]
const labels = ["Fit", "New", "Processed", "Unprocessed", "Total"]

function Payment(): JSX.Element {
    return (
        <Layout
            title="Payments"
            subtitle="Enter the Payments made from the ECI Inventory. Only full bundles of Banknotes may be withdrawn from the ECI Inventory."
            tableTitle="Enter payments to Inventory for October 4, 2022"
            buttonText="Payment"
            data={getData(list)}
            dataInventory={getInventoryData(labels)}
        />
    )
}

export default Payment
