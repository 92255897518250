export const columns = [
    { header: "", accessor: "name" },
    { header: "$100", accessor: "key100" },
    { header: "$50", accessor: "key50" },
    { header: "$20", accessor: "key20" },
    { header: "$5", accessor: "key5" },
    { header: "$2", accessor: "key2" },
    { header: "$1", accessor: "key1" },
    { header: "Total", accessor: "total" },
]

export const getData = () => {
    const labels = ["$100", "$50", "$20", "$10", "$5", "$1", "total"]
    return labels.map((name) => {
        return {
            name,
            amountFit: 0,
            amountNew: 0,
            amountProcessed: 0,
            amountUnprocessed: 0,
            total: 0,
        }
    })
}

export const keys = ["$100", "$50", "$20", "$10", "$5", "$1"]

export const options = keys.map((key) => {
    return { label: key, value: key }
})
export const categories = [{ name: "fit" }, { name: "new" }, { name: "processed" }]

export const defaultValues = {
    fit: [
        {
            key: "",
            value: 0,
        },
    ],
    new: [
        {
            key: "",
            value: 0,
        },
    ],
    processed: [
        {
            key: "",
            value: 0,
        },
    ],
    unprocessed: [
        {
            key: "",
            value: 0,
        },
    ],
}
