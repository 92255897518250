import { useState } from "react"
import Table, { getDynamicData, getTotalData } from "src/components/common/table"
import Screen from "src/components/layout/screen"
import Button from "src/components/common/button"
import { getMoneyFormat } from "src/utils/string"
import { columns } from "./index.data"
import { Props } from "./index.types"
import "./index.css"

function Payment({
    title = "",
    subtitle = "",
    data = [],
    buttonText = "",
    tableTitle = "",
    dataInventory = [],
}: Props): JSX.Element {
    const [state, setState] = useState<any[]>(data)

    const modifyState = (): boolean => JSON.stringify(state) !== JSON.stringify(data)

    const getTotal = (): string => getMoneyFormat(getTotalData(state))

    const handleReset = () => setState([...data])

    const handleClick = (): void => {
        alert("Click")
        console.log(state)
    }

    return (
        <Screen className="payment__screen">
            <div className="payment__head">
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </div>
            <div className="payment__main">
                <Table
                    containerClassName="payment__table__box"
                    className="payment__table__editable"
                    title={tableTitle}
                    columns={columns}
                    data={state}
                    editable={true}
                    border={false}
                    showTotal={false}
                    onChange={({ index, key, value }) => {
                        let picker: any[] = [...state]
                        picker[index] = { ...picker[index], [key]: value }
                        const dinamyc = getDynamicData(picker, "total", { verticalTotals: true })
                        setState([...dinamyc])
                    }}
                />
                <Button className="payment__button" disabled={!modifyState()} onClick={handleClick}>
                    {`${buttonText} ${getTotal()}`}
                </Button>
                <Button type="link" disabled={!modifyState()} onClick={handleReset}>
                    Reset
                </Button>
                <Table
                    containerClassName="payment__table__card"
                    title="Vault inventory as for October 4, 2022"
                    columns={columns.map((item, index) => {
                        return { ...item, bold: index === 0 }
                    })}
                    data={dataInventory}
                />
            </div>
        </Screen>
    )
}

export default Payment
