import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Card from "src/components/common/card"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import Select from "src/components/common/select"
import { Input } from "src/components/common/input"
import Link from "src/components/common/link"
import { useState } from "react"
import Icon from "src/assets/icons"
import Button from "src/components/common/button"
import { data, columns } from "src/pages/vault/open/index.data"
import Table from "src/components/common/table"
import { useNavigate } from "react-router-dom"

function ShipmentOut(): JSX.Element {
    const navigate = useNavigate()

    const [showAdditional, setShowAdditional] = useState<boolean>(false)
    const bagAdditional = {
        classification: "",
        denomination: "",
        dollarAmount: "",
        sealNumber: "",
    }
    const { control, watch, getValues, reset } = useForm({
        defaultValues: {
            bag: {
                classification: "",
                denomination: "",
                dollarAmount: "",
                sealNumber: "",
                additional: [bagAdditional],
            },
        },
    })
    watch()
    const { fields, insert, append, remove } = useFieldArray({
        control,
        name: "bag.additional",
    })

    function onSubmit() {
        console.log("values", getValues())
    }

    function setButton() {
        if (Object.values(getValues().bag).some((obj) => Object.values(obj).some((values) => values === ""))) {
            return true
        } else {
            return false
        }
    }

    return (
        <Screen style={{ background: "white" }}>
            <ContainerTop
                style={{ height: "160px" }}
                onBack={() => navigate("/shipment")}
                back={true}
                title="Shipments Out"
                subtitle="Enter bag preparation information in the Shipment Details for releasing a Shipment Out."
            />
            <section className="shipments_out">
                <div className="shipments_out__columns">
                    <div className="shipments_out__columns--left">
                        <span className="shipments_out__columns__title">Shipment Details</span>
                        <div className="shipments_out__columns__form">
                            <div className="shipments_out__columns__form__section">
                                <span className="shipments_out__columns__form__section__title">Bag 1</span>
                                <div className="shipments_out__columns__form__section__inputs">
                                    <div className="shipments_out__columns__form__section__inputs--1">
                                        <span className="shipments_out__columns__form__section__inputs__title">
                                            Classification
                                        </span>
                                        <Controller
                                            name="bag.classification"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    placeholder="Classification"
                                                    icon="classification"
                                                    options={[
                                                        { label: "Fit", value: "Fit" },
                                                        { label: "Fit", value: "Fit" },
                                                        { label: "Fit", value: "Fit" },
                                                        { label: "Fit", value: "Fit" },
                                                    ]}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="shipments_out__columns__form__section__inputs--2">
                                        <span className="shipments_out__columns__form__section__inputs__title">
                                            Denomination
                                        </span>
                                        <Controller
                                            name="bag.denomination"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    placeholder="Denomination"
                                                    icon="denomination"
                                                    options={[
                                                        { label: "$1", value: "$1" },
                                                        { label: "$2", value: "$2" },
                                                        { label: "$5", value: "$5" },
                                                        { label: "$20", value: "$20" },
                                                        { label: "$50", value: "$50" },
                                                        { label: "$100", value: "$100" },
                                                    ]}
                                                    onChange={onChange}
                                                    value={value}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="shipments_out__columns__form__section__inputs--3">
                                        <span className="shipments_out__columns__form__section__inputs__title">
                                            Dollar Amount
                                        </span>
                                        <Controller
                                            name="bag.dollarAmount"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    placeholder="Dollar amount"
                                                    icon="dollar"
                                                    type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="shipments_out__columns__form__section__inputs__item"
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="shipments_out__columns__form__section__inputs--4">
                                        <span className="shipments_out__columns__form__section__inputs__title">
                                            Seal Number
                                        </span>
                                        <Controller
                                            name="bag.sealNumber"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Input
                                                    placeholder="Seal number"
                                                    icon="sealNumber"
                                                    type="number"
                                                    onChange={onChange}
                                                    value={value}
                                                    className="shipments_out__columns__form__section__inputs__item"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {fields.map((item, index) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={
                                            showAdditional
                                                ? "shipments_out__columns__form__section"
                                                : "shipments_out__columns__form__section--additional"
                                        }
                                    >
                                        <span className="shipments_out__columns__form__section__title">
                                            Bag {index + 2}
                                        </span>
                                        <div className="shipments_out__columns__form__section__inputs">
                                            <div className="shipments_out__columns__form__section__inputs--1">
                                                <span className="shipments_out__columns__form__section__inputs__title">
                                                    Classification
                                                </span>
                                                <Controller
                                                    name={`bag.additional.${index}.classification`}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            placeholder="Classification"
                                                            icon="dollar"
                                                            options={[
                                                                { label: "Fit", value: "Fit" },
                                                                { label: "Fit", value: "Fit" },
                                                                { label: "Fit", value: "Fit" },
                                                                { label: "Fit", value: "Fit" },
                                                            ]}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="shipments_out__columns__form__section__inputs--2">
                                                <span className="shipments_out__columns__form__section__inputs__title">
                                                    Denomination
                                                </span>
                                                <Controller
                                                    name={`bag.additional.${index}.denomination`}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            placeholder="Denomination"
                                                            icon="dollar"
                                                            options={[
                                                                { label: "$1", value: "$1" },
                                                                { label: "$2", value: "$2" },
                                                                { label: "$5", value: "$5" },
                                                                { label: "$20", value: "$20" },
                                                                { label: "$50", value: "$50" },
                                                                { label: "$100", value: "$100" },
                                                            ]}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="shipments_out__columns__form__section__inputs--3">
                                                <span className="shipments_out__columns__form__section__inputs__title">
                                                    Dollar Amount
                                                </span>
                                                <Controller
                                                    name={`bag.additional.${index}.dollarAmount`}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Input
                                                            placeholder="Dollar amount"
                                                            icon="dollar"
                                                            type="number"
                                                            onChange={onChange}
                                                            value={value}
                                                            className="shipments_out__columns__form__section__inputs__item"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="shipments_out__columns__form__section__inputs--4">
                                                <span className="shipments_out__columns__form__section__inputs__title">
                                                    Seal Number
                                                </span>
                                                <Controller
                                                    name={`bag.additional.${index}.sealNumber`}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Input
                                                            placeholder="Seal number"
                                                            icon="dollar"
                                                            type="number"
                                                            onChange={onChange}
                                                            value={value}
                                                            className="shipments_out__columns__form__section__inputs__item"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <Link
                                icon="add"
                                text="Add bag"
                                onClick={() => {
                                    const docs = getValues().bag.additional.length
                                    docs
                                        ? getValues().bag.additional[docs - 1].classification
                                            ? append(bagAdditional)
                                            : setShowAdditional(true)
                                        : insert(docs, bagAdditional)
                                }}
                            />
                        </div>
                    </div>
                    <Card className="shipments_out__columns--right">
                        <span className="shipments_out__columns__title">Shipment ID: 918273645</span>
                        <div className="shipments_out__columns__card">
                            <span className="shipments_out__columns__card__title">Bag 1</span>
                            <div className="shipments_out__columns__values">
                                <span className="shipments_out__columns__values--title">Dollar amount</span>
                                <span className="shipments_out__columns__values--amount">
                                    {getValues().bag.dollarAmount ? "$" + getValues().bag.dollarAmount : "$0"}
                                </span>
                            </div>
                            <div className="shipments_out__columns__values">
                                <span className="shipments_out__columns__values--title">Denomination</span>
                                <span className="shipments_out__columns__values--amount">
                                    {getValues().bag.denomination ? getValues().bag.denomination : "$0"}
                                </span>
                            </div>
                            <div className="shipments_out__columns__values">
                                <span className="shipments_out__columns__values--title">Seal number</span>
                                <span className="shipments_out__columns__values--amount">
                                    {getValues().bag.sealNumber ? getValues().bag.sealNumber : "-"}
                                </span>
                            </div>
                            <Icon name={"trashcan"} style={{ opacity: "0.5" }} />
                        </div>
                        {showAdditional && (
                            <div>
                                {getValues().bag.additional.map((additional, index) => (
                                    <div className="shipments_out__columns__card" key={index}>
                                        <span className="shipments_out__columns__card__title">Bag {index + 2}</span>
                                        <span className="shipments_out__columns__values--amount-add">
                                            {additional.dollarAmount ? "$" + additional.dollarAmount : "$0"}
                                        </span>
                                        <span className="shipments_out__columns__values--amount-add">
                                            {additional.denomination ? additional.denomination : "$0"}
                                        </span>
                                        <span className="shipments_out__columns__values--amount-add">
                                            {additional.sealNumber ? additional.sealNumber : "-"}
                                        </span>

                                        <Icon
                                            name={"trashcan"}
                                            onClick={() => remove(index)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <Button
                            disabled={setButton()}
                            onClick={() => onSubmit()}
                            style={{ width: "100%", alignSelf: "center", marginTop: "40px" }}
                        >
                            Submit
                        </Button>
                        <div className="shipments_out__columns__link">
                            <Link
                                text="Reset"
                                onClick={() => {
                                    reset()
                                    setShowAdditional(false)
                                }}
                            />
                        </div>
                    </Card>
                </div>
                <Card className="shipments_out__table">
                    <Table
                        data={data}
                        columns={columns}
                        title="Vault inventory as for October 4, 2022"
                        border={true}
                        editable={false}
                        containerClassName="open-vault-table"
                    />
                </Card>
            </section>
        </Screen>
    )
}

export default ShipmentOut
