import { Props } from "./index.types"
import cx from "classnames"
import Card from "src/components/common/card"
import "./index.css"
import Icon from "src/assets/icons"

function Notification(props: Props) {
    const { className = "", style = {}, notifications = [] } = props
    return (
        <Card className={cx("notification__container", className)} style={style}>
            <div className="notification__header">
                <h4>Notifications</h4>
                <Icon name="settings" />
            </div>
            <div className="notification__contain">
                {notifications?.map(({ title = "", text = "", read = "", time = "" }, index) => (
                    <div className="notification__item" key={index} onClick={() => console.log(title)}>
                        <div className="notification__item__container--read">
                            <span className={read ? "notification__item--read" : ""}></span>
                        </div>
                        <div>
                            <p className="notification__item__title">{title}</p>
                            <p className="notification__item__text">{text}</p>
                            <p className="notification__item__text-time">{time}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    )
}

export default Notification
