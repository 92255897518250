import cx from "classnames"
import { Props } from "./index.type"
import "./index.css"
import Icon from "src/assets/icons"
import Dropdown from "../dropdown"
import { useState } from "react"

function Header(props: Props): JSX.Element {
    const { className = "", selected = "vault" } = props
    const [bank, setBank] = useState("Bank A")
    return (
        <div className={cx("header", className)}>
            <div className="header__container">
                <Icon name="logo" />
                <span className="header__logo">Currency Reserve</span>
                <span className={selected === "vault" ? "header__option--selected" : "header__option"}>Vault</span>
                <span className={selected !== "vault" ? "header__option--selected" : "header__option"}>Reports</span>
                <div className="header__dropdowns">
                    <Dropdown
                        items={[
                            {
                                label: "Bank A",
                                secondary: "Extended Custodial Inventory",
                                onClick: () => setBank("Bank A"),
                            },
                            {
                                label: "Bank B",
                                secondary: "Extended Custodial Inventory",
                                onClick: () => setBank("Bank B"),
                            },
                            {
                                label: "Bank C",
                                secondary: "Extended Custodial Inventory",
                                onClick: () => setBank("Bank C"),
                            },
                        ]}
                    >
                        <div className="header__dropdowns__item">
                            <Icon name="bank" />
                            <span>{bank}</span>
                            <Icon name="chevronDown" />
                        </div>
                    </Dropdown>
                    <div className="header__icons">
                        <Icon name="notification" />
                        <Dropdown
                            items={[
                                {
                                    label: "User name_1234",
                                    secondary: "Login time: 04/10/22 12:00:00 AM",
                                    onClick: () => alert("User name_1234"),
                                },
                                {
                                    label: "Site time",
                                    secondary: "October 4, 2022 12:00:00 AM",
                                    onClick: () => alert("Site time"),
                                },
                                {
                                    label: "Office time",
                                    secondary: "October 4, 2022 12:00:00 AM",
                                    onClick: () => alert("Office time"),
                                },
                            ]}
                            dropStyle={{ right: "10px" }}
                            type={2}
                        >
                            <div className="header__icons__user">
                                <Icon name="user" />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
