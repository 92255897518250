import { memo, SVGProps } from "react"

const ReceiptEditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={31} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20.043 2.5h-9.45c-4.725 0-5.9 1.263-5.9 6.3v14.075c0 3.325 1.825 4.113 4.037 1.738l.013-.013c1.025-1.088 2.587-1 3.475.188l1.262 1.687c1.013 1.337 2.65 1.337 3.663 0l1.262-1.688c.9-1.2 2.463-1.287 3.488-.187 2.225 2.375 4.037 1.587 4.037-1.738V8.8c.013-5.038-1.162-6.3-5.887-6.3Zm-1.175 9.988-.625.637h-.013l-3.787 3.788c-.163.162-.5.337-.738.362l-1.687.25c-.613.088-1.038-.35-.95-.95l.237-1.7c.038-.238.2-.563.363-.738l3.8-3.787.625-.637C16.505 9.3 16.968 9 17.468 9c.425 0 .887.2 1.4.713 1.125 1.125.762 2.012 0 2.775Z"
            fill="#308B57"
        />
    </svg>
)

const ReceiptEditIconMemo = memo(ReceiptEditIcon)
export default ReceiptEditIconMemo
