import { memo, SVGProps } from "react"

const CubeRotate = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 22.75a.752.752 0 0 1-.64-1.14l1.05-1.75c.21-.35.67-.47 1.03-.26.36.21.47.67.26 1.03l-.27.45c2.76-.65 4.83-3.13 4.83-6.09 0-.41.34-.75.75-.75s.75.34.75.75c-.01 4.28-3.49 7.76-7.76 7.76ZM2 9.75c-.41 0-.75-.34-.75-.75 0-4.27 3.48-7.75 7.75-7.75a.752.752 0 0 1 .64 1.14L8.59 4.14c-.21.35-.67.47-1.03.25a.746.746 0 0 1-.25-1.03l.27-.45A6.261 6.261 0 0 0 2.75 9c0 .41-.34.75-.75.75ZM16.4 8.21l-3.76-2.03c-.4-.21-.87-.21-1.27 0L7.6 8.21c-.27.15-.44.44-.44.76 0 .33.17.62.44.77l3.76 2.03c.2.11.42.16.64.16.22 0 .44-.05.64-.16l3.76-2.03a.87.87 0 0 0 .44-.77c0-.32-.17-.61-.44-.76ZM10.74 12.47l-3.5-1.75a.867.867 0 0 0-.84.04.86.86 0 0 0-.4.73v3.31c0 .57.32 1.09.83 1.34l3.5 1.75c.12.06.25.09.39.09.16 0 .31-.04.45-.13.26-.16.41-.43.41-.73v-3.31c-.01-.57-.32-1.08-.84-1.34ZM17.59 10.76a.867.867 0 0 0-.84-.04l-3.5 1.75a1.5 1.5 0 0 0-.83 1.34v3.31c0 .3.15.57.41.73.14.09.29.13.45.13.13 0 .26-.03.39-.09l3.5-1.75c.51-.26.83-.77.83-1.34v-3.31c0-.3-.15-.57-.41-.73Z"
                fill="#fff"
            />
        </svg>
    )
})

export default CubeRotate
