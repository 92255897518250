import { memo, SVGProps } from "react"

const BankIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M0 4.63v3.06h1.634v7.626H0v3.268h18.52v-3.268h-1.634V7.69h1.634V4.63L9.26 0 0 4.63Zm7.626 10.686H4.902V7.69h2.724v7.626Zm3.268-7.626h2.724v7.626h-2.724V7.69Z"
            fill="#287248"
        />
    </svg>
)
const BankIconMemo = memo(BankIcon)

export default BankIconMemo
