import { memo, SVGProps } from "react"

const WalletMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M26.212 20.1c-.3 3.337-2.712 5.525-6.212 5.525H8.75c-3.45 0-6.25-2.8-6.25-6.25v-8.75c0-3.4 2.05-5.775 5.237-6.175.325-.05.663-.075 1.013-.075H20c.325 0 .637.013.938.063 2.987.35 5.012 2.437 5.274 5.462.038.362-.262.662-.625.662H23.65c-1.2 0-2.313.463-3.112 1.288a4.397 4.397 0 0 0-1.313 3.525c.2 2.275 2.2 4.063 4.575 4.063h1.787c.363 0 .663.3.625.662Z"
            fill="#308B57"
        />
        <path
            d="M27.5 13.713v2.575c0 .687-.55 1.25-1.25 1.274H23.8c-1.35 0-2.588-.987-2.7-2.337a2.51 2.51 0 0 1 .75-2.037c.462-.476 1.1-.75 1.8-.75h2.6c.7.024 1.25.587 1.25 1.274Z"
            fill="#308B57"
        />
    </svg>
)

const WalletMoneyIconMemo = memo(WalletMoneyIcon)
export default WalletMoneyIconMemo
