import Layout from "src/components/sections/transfers/prepak"
import { getInventoryData } from "src/components/sections/payment/index.data"
import { categories, columnsSummary } from "./index.data"

export const labels = ["Fit", "New", "Processed", "Unprocessed", "Total"]

function ProcessingIn(): JSX.Element {
    return <Layout dataInventory={getInventoryData(labels)} columnsSummary={columnsSummary} categories={categories} />
}
export default ProcessingIn
