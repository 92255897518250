import { useEffect } from "react"

export function useOutside(ref: any, onEvent: () => void): void {
    useEffect(() => {
        function handleOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                if (onEvent && typeof onEvent === "function") onEvent()
            }
        }
        function handlekeyOutside(event: any) {
            if (event.key === "Escape") {
                if (onEvent && typeof onEvent === "function") onEvent()
            }
        }
        document.addEventListener("keydown", handlekeyOutside, true)
        document.addEventListener("mousedown", handleOutside)
        return () => {
            document.addEventListener("keydown", handlekeyOutside, true)
            document.removeEventListener("mousedown", handleOutside)
        }
    }, [ref])
}
