export const columns = [
    { header: "", accessor: "name" },
    { header: "$100", accessor: "0" },
    { header: "$50", accessor: "1" },
    { header: "$20", accessor: "2" },
    { header: "$5", accessor: "3" },
    { header: "$2", accessor: "4" },
    { header: "$1", accessor: "5" },
    { header: "Total", accessor: "total" },
]
export const dataTableFit = [
    {
        name: "Original amount",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
    {
        name: "Adjusted amount",
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        total: 0,
    },
    {
        name: "Adjustment result",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
]

export const dataTableNew = [
    {
        name: "Original amount",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
    {
        name: "Adjusted amount",
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        total: 0,
    },
    {
        name: "Adjustment result",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
]

export const dataTableProcessed = [
    {
        name: "Original amount",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
    {
        name: "Adjusted amount",
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        total: 0,
    },
    {
        name: "Adjustment result",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
]

export const dataTableUnprocessed = [
    {
        name: "Original amount",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
    {
        name: "Adjusted amount",
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        total: 0,
    },
    {
        name: "Adjustment result",
        "0": 999999999,
        "1": 999999999,
        "2": 999999999,
        "3": 999999999,
        "4": 999999999,
        "5": 999999999,
        total: 999999999,
    },
]

export const dataCollapse = [
    {
        name: "Fit",
        data: "fit",
    },
    {
        name: "New",
        data: "new",
    },
    {
        name: "Processed",
        data: "processed",
    },
    {
        name: "Unprocessed",
        data: "unprocessed",
    },
]
