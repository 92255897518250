export const dataCards = [
    {
        icon: "walletMoney",
        title: "Opening balance",
        text: "200,000,000.00",
    },
    {
        icon: "walletMoney",
        title: "Opening balance",
        text: "200,000,000.00",
    },
    {
        icon: "walletMoney",
        title: "Opening balance",
        text: "200,000,000.00",
        operation: "Deposit",
    },
]
export const notifications = [
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: true,
        time: "5 mins ago",
    },
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: false,
        time: "15 mins ago",
    },
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: true,
        time: "20 mins ago",
    },
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: false,
        time: "25 mins ago",
    },
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: true,
        time: "30 mins ago",
    },
    {
        title: "Lorem ipsum dolor set",
        text: "Lorem ipsum dolor sit amet consectetur...",
        read: false,
        time: "1h ago",
    },
]
export const data = [
    {
        name: "Fit",
        "0": "999,999,999",
        "1": "999,999,999",
        "2": "999,999,999",
        "3": "999,999,999",
        "4": "999,999,999",
        "5": "999,999,999",
        total: "999,999,999",
    },
    {
        name: "New",
        "0": "999,999,999",
        "1": "999,999,999",
        "2": "999,999,999",
        "3": "999,999,999",
        "4": "999,999,999",
        "5": "999,999,999",
        total: "999,999,999",
    },
    {
        name: "Processed",
        "0": "999,999,999",
        "1": "999,999,999",
        "2": "999,999,999",
        "3": "999,999,999",
        "4": "999,999,999",
        "5": "999,999,999",
        total: "999,999,999",
    },
    {
        name: "Unprocessed",
        "0": "999,999,999",
        "1": "999,999,999",
        "2": "999,999,999",
        "3": "999,999,999",
        "4": "999,999,999",
        "5": "999,999,999",
        total: "999,999,999",
    },
    {
        name: "Total",
        "0": "999,999,999",
        "1": "999,999,999",
        "2": "999,999,999",
        "3": "999,999,999",
        "4": "999,999,999",
        "5": "999,999,999",
        total: "999,999,999",
    },
]
export const columns = [
    { header: "", accessor: "name" },
    { header: "$100", accessor: "0" },
    { header: "$50", accessor: "1" },
    { header: "$20", accessor: "2" },
    { header: "$5", accessor: "3" },
    { header: "$2", accessor: "4" },
    { header: "$1", accessor: "5" },
    { header: "Total", accessor: "total" },
]
