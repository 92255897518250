import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Select from "src/components/common/select"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { Input } from "src/components/common/input"
import Icon from "src/assets/icons"
import { useEffect, useState } from "react"
import Link from "src/components/common/link"
import CountrySelect from "src/components/common/select/countrySelect"
import Button from "src/components/common/button"

function CountryFlow() {
    const defaultAdditional = {
        country: "",
        receipt: "",
        payment: "",
    }
    const [totalReceipts, setTotalReceipts] = useState<number>(0)
    const [totalPayments, setTotalPayments] = useState<number>(0)

    const { control, getValues, watch, reset } = useForm({
        defaultValues: {
            amount: {
                country: "",
                receipt: "",
                payment: "",
                additional: [defaultAdditional, defaultAdditional],
            },
        },
    })
    watch()
    const { fields, remove, insert, append } = useFieldArray({
        control,
        name: "amount.additional",
    })

    function onSubmit() {
        console.log("values", getValues())
    }

    useEffect(() => {
        const values = getValues()
        let receipts = values.amount.receipt ? parseFloat(values.amount.receipt) : 0
        values.amount.additional.map((amount) => {
            const newRow = amount.receipt ? parseFloat(amount.receipt) : 0
            receipts = receipts + newRow
        })
        setTotalReceipts(receipts)
        let payments = values.amount.payment ? parseFloat(values.amount.payment) : 0
        values.amount.additional.map((amount) => {
            const newRow = amount.payment ? parseFloat(amount.payment) : 0
            payments = payments + newRow
        })
        setTotalPayments(payments)
    })

    function setButton() {
        if (Object.values(getValues().amount).some((obj) => Object.values(obj).some((values) => values === ""))) {
            return true
        } else {
            return false
        }
    }

    return (
        <Screen className="country">
            <ContainerTop className="country__header">
                <div className="country__header__title">
                    <span className="country__header__title__item">Country Flow for</span>
                    <Select
                        type={2}
                        value="Bank A"
                        options={[
                            { label: "Bank A", value: "Bank A" },
                            { label: "Bank B", value: "Bank B" },
                            { label: "Bank C", value: "Bank C" },
                        ]}
                    />
                </div>
                <span className="country__header__subtitle">
                    Enter the payments and receipts of US Banknotes in full dollar bundles to and from foreign
                    countries.
                </span>
                <div className="country__header__selects">
                    <Select
                        icon="region"
                        placeholder="Region"
                        options={[
                            { label: "North America", value: "North America" },
                            { label: "North America", value: "North America" },
                            { label: "North America", value: "North America" },
                        ]}
                    />
                    <Select
                        icon="reserveOffice"
                        placeholder="Reserve Office of Origin"
                        options={[
                            { label: "New York", value: "New York" },
                            { label: "New York", value: "New York" },
                            { label: "New York", value: "New York" },
                        ]}
                    />
                    <Select
                        icon="calenderBasic"
                        placeholder="Month Ending"
                        options={[
                            { label: "31/10/22", value: "31/10/22" },
                            { label: "31/10/22", value: "31/10/22" },
                            { label: "31/10/22", value: "31/10/22" },
                        ]}
                    />
                </div>
            </ContainerTop>
            <div className="country__titles">
                <span>Country</span>
                <span>Receipts</span>
                <span>Payments</span>
            </div>
            <div className="country__body">
                <div className="country__body__row">
                    <Controller
                        name="amount.country"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <CountrySelect icon="country" placeholder="Country" onChange={onChange} value={value} />
                        )}
                    />
                    <Controller
                        name="amount.receipt"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                placeholder="Dollar amount"
                                icon="dollar"
                                type="number"
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Controller
                        name="amount.payment"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Input
                                placeholder="Dollar amount"
                                icon="dollar"
                                type="number"
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />
                    <Icon name="trashcan" style={{ opacity: "0.5" }} />
                </div>
                {fields.map((item, index) => {
                    return (
                        <div key={item.id} className={"country__body__row"}>
                            <Controller
                                name={`amount.additional.${index}.country`}
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CountrySelect
                                        icon="country"
                                        placeholder="Country"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name={`amount.additional.${index}.receipt`}
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder="Dollar amount"
                                        icon="dollar"
                                        type="number"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name={`amount.additional.${index}.payment`}
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        placeholder="Dollar amount"
                                        icon="dollar"
                                        type="number"
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                            <Icon name="trashcan" onClick={() => remove(index)} />
                        </div>
                    )
                })}
            </div>
            <div className="country__add">
                <Link
                    icon="add"
                    text="Add other"
                    onClick={() => {
                        const docs = getValues().amount.additional.length
                        docs
                            ? getValues().amount.additional[docs - 1].country
                                ? append({ country: "", receipt: "", payment: "" })
                                : append({ country: "", receipt: "", payment: "" })
                            : insert(docs, { country: "", receipt: "", payment: "" })
                    }}
                />
                <div className="country__add__totals">
                    <span>Total Receipts: ${totalReceipts ? totalReceipts : "0"}</span>
                    <span>Total Payments: ${totalPayments ? totalPayments : "0"}</span>
                </div>
            </div>
            <Button
                disabled={setButton()}
                onClick={() => onSubmit()}
                style={{ width: "80%", alignSelf: "center", marginTop: "40px" }}
            >
                Submit
            </Button>
            <div className="country__link">
                <Link
                    text="Reset"
                    onClick={() => {
                        reset()
                    }}
                />
            </div>
        </Screen>
    )
}

export default CountryFlow
