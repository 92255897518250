import { memo, SVGProps } from "react"

const GlobalRefresh = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1em" height="1em" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M22.65 18.13c-.1-.4-.5-.65-.9-.55-.4.1-.65.5-.56.9A2.253 2.253 0 0 1 19 21.25c-1.24 0-2.25-1.01-2.25-2.25 0-1.1.79-2.01 1.84-2.21-.1.23-.09.51.07.73.15.2.38.31.61.31.15 0 .31-.05.44-.14l1.17-.85c.01-.01.01-.02.02-.02.01-.01.02-.01.03-.02a.36.36 0 0 0 .07-.1c.03-.04.07-.07.09-.12.02-.04.03-.09.05-.13.01-.05.03-.09.04-.14.01-.05 0-.09-.01-.14 0-.05 0-.1-.02-.15-.01-.05-.04-.09-.06-.13-.02-.04-.03-.08-.05-.11-.01-.01-.02-.01-.03-.02-.01 0-.01-.01-.01-.02l-1-1.15a.747.747 0 0 0-1.06-.07c-.22.2-.3.49-.22.76-1.93.15-3.46 1.75-3.46 3.72 0 2.07 1.68 3.75 3.75 3.75 2.07 0 3.75-1.68 3.75-3.75-.01-.29-.04-.59-.11-.87ZM2.03 14.63c0 .03-.02.07-.02.1a9.494 9.494 0 0 0 4.26 4.26c.03 0 .07-.02.1-.02-.34-1.16-.6-2.35-.79-3.54-1.2-.2-2.39-.46-3.55-.8ZM19.07 6.43a9.5 9.5 0 0 0-4.49-4.49c.36 1.19.66 2.41.86 3.63 1.22.2 2.44.49 3.63.86ZM1.93 6.43c1.2-.36 2.42-.66 3.64-.86.2-1.19.45-2.37.79-3.53-.03 0-.07-.02-.1-.02-1.88.93-3.41 2.5-4.33 4.41ZM13.82 5.36c-.24-1.3-.54-2.6-.97-3.86-.02-.07-.02-.13-.03-.21C12.08 1.11 11.3 1 10.5 1c-.81 0-1.58.11-2.33.3-.01.07 0 .13-.02.21-.42 1.26-.73 2.55-.97 3.85 2.21-.24 4.43-.24 6.64 0ZM5.36 7.18c-1.31.24-2.59.55-3.86.97-.07.02-.13.02-.2.03-.19.74-.3 1.52-.3 2.32 0 .81.11 1.58.3 2.33.07.01.13 0 .21.02 1.26.42 2.55.73 3.86.97-.25-2.21-.25-4.43-.01-6.64ZM19.7 8.18c-.07 0-.13-.01-.21-.03-1.26-.42-2.56-.73-3.86-.97.25 2.21.25 4.43 0 6.63 1.3-.24 2.6-.54 3.86-.97.07-.02.13-.01.21-.02.18-.75.3-1.52.3-2.33 0-.79-.11-1.56-.3-2.31ZM7.18 15.64c.24 1.31.54 2.6.97 3.86.02.07.01.13.02.21.75.18 1.52.29 2.33.29.8 0 1.58-.11 2.32-.3.01-.07.01-.13.03-.21.42-1.26.73-2.55.97-3.86-1.1.12-2.21.21-3.32.21-1.11 0-2.22-.08-3.32-.2ZM6.95 6.95c-.3 2.36-.3 4.74 0 7.11 2.36.3 4.74.3 7.11 0 .3-2.36.3-4.74 0-7.11-2.37-.3-4.75-.3-7.11 0Z"
                fill="#fff"
            />
        </svg>
    )
})

export default GlobalRefresh
