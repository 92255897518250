import cx from "classnames"
import { useState, useEffect } from "react"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import Icon from "src/assets/icons"
import Button from "src/components/common/button"
import { Field, CurrencyInput } from "src/components/common/input"
import Select from "src/components/common/select"
import Table, { getDynamicData, getTotalData } from "src/components/common/table"
import { getMoneyFormat } from "src/utils/string"
import { defaultValues, itemField, options, columns, keys } from "./index.data"
import { Value } from "./index.types"
import "./index.css"

function OutForProcessing(): JSX.Element {
    const [values, setValues] = useState<Value[]>([])
    const { control, getValues, watch, reset } = useForm({
        defaultValues,
    })
    const { fields, append, remove } = useFieldArray({ control, name: "values" })

    useEffect(() => {
        let data = getValues("values")
        if (JSON.stringify(values) !== JSON.stringify(data)) {
            setValues(data)
        }
    }, [watch("values")])

    const getEnabledItem = (index: number): boolean => {
        return values?.[index]?.key && values?.[index]?.value ? true : false
    }

    const getReadData = () => {
        return [...keys, "Total"].map((name) => {
            let cell = values.filter(({ key }) => key === name)
            return {
                name,
                amount: cell?.[0]?.value || 0,
                total: 0,
            }
        })
    }

    const dataSource = getDynamicData(getReadData(), "total", { verticalTotals: true })

    const onSubmit = (): void => {
        console.log(values)
    }

    return (
        <form className={cx("out-processing")}>
            <div className="out-processing__form">
                <h3 className="out-processing__form__title">Unprocessed</h3>
                {fields.map((_, index) => (
                    <div className="out-processing__form__row" key={index}>
                        <Field label="Denomination" className="out-processing__form__field_1">
                            <Controller
                                control={control}
                                name={`values.${index}.key`}
                                render={({ field: { value, onChange } }) => (
                                    <Select
                                        className="out-processing__form__select"
                                        icon="boldKey"
                                        placeholder="Denomination"
                                        options={options}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Field>
                        <Field label="Dollar amount" className="out-processing__form__field_2">
                            <Controller
                                control={control}
                                name={`values.${index}.value`}
                                render={({ field: { value, onChange } }) => (
                                    <CurrencyInput
                                        className="out-processing__form__input"
                                        icon="dollar"
                                        placeholder="Dollar amount"
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </Field>
                        {index !== 0 && (
                            <div
                                className="out-processing__form__touch"
                                style={{ opacity: getEnabledItem(index) ? 1 : 0.5 }}
                                onClick={getEnabledItem(index) ? () => remove(index) : undefined}
                            >
                                <Icon name={"trashcan"} />
                            </div>
                        )}
                    </div>
                ))}

                <Button className="out-processing__form__link" type={"link"} onClick={() => append(itemField)}>
                    {"+ Add denomination"}
                </Button>
            </div>
            <div className="out-processing__card">
                <Table
                    containerClassName="out-processing__table__box"
                    title="Transfer Summary"
                    className="out-processing__table"
                    columns={columns}
                    data={dataSource}
                    border={false}
                />
                <Button className="out-processing__button" onClick={onSubmit}>{`Submit ${getMoneyFormat(
                    getTotalData(dataSource)
                )}`}</Button>
                <Button type={"link"} onClick={() => reset(defaultValues)}>
                    Reset
                </Button>
            </div>
        </form>
    )
}

export default OutForProcessing
