import { useState } from "react"
import { useNavigate } from "react-router-dom"
import cx from "classnames"
import Icon from "src/assets/icons"
import { Props } from "./index.type"
import "./index.css"

function Drawer(props: Props): JSX.Element {
    const { className = "", style = {}, items = [], current = "" } = props
    const [expand, setExpand] = useState<boolean>(false)

    const navigate = useNavigate()

    const handleSelect = (path: string): void => {
        navigate(path)
        if (expand) setExpand(false)
    }

    return (
        <section className={cx("drawer__container", expand ? "drawer--max" : "drawer--min", className)} style={style}>
            <div className="drawer__btn flex-center" onClick={() => setExpand(!expand)}>
                <Icon
                    name="chevronRight"
                    className={`drawer__btn__icon${expand ? " drawer__btn__icon--rotate" : ""}`}
                />
            </div>
            <div className="drawer__content">
                <div>
                    {items?.map(({ label = "", path = "", icon = "" }, index) => (
                        <div
                            className={cx({
                                drawer__item: true,
                                "drawer__item--active": path === current,
                            })}
                            key={index}
                            onClick={() => handleSelect(path)}
                        >
                            <Icon name={icon} className="drawer__item__icon" />
                            <span className="drawer__item__label">{label}</span>
                        </div>
                    ))}
                </div>
                <div className="drawer__item">
                    <Icon name="help" className="drawer__item__icon" />
                    <span className="drawer__item__label">{"Help"}</span>
                </div>
            </div>
        </section>
    )
}

export default Drawer
