import { Props, Collection } from "./index.types"
import calendar from "./calendar"
import receiptEdit from "./receipt-edit"
import walletMoney from "./wallet-money"
import logo from "./logo"
import bank from "./bank"
import chevronDown from "./chevron-down"
import notification from "./notification"
import user from "./user"
import logout from "./logout"
import settings from "./settings"
import help from "./help"
import strongBox from "./strong-box"
import globalRefresh from "./global-refresh"
import chevronRight from "./chevron-right"
import boldKey from "./bold-key"
import region from "./region"
import reserveOffice from "./reserve-office"
import calenderBasic from "./calender-basic"
import country from "./country"
import dollar from "./dollar"
import add from "./add"
import trashcan from "./delete"
import vaultOpened from "./vault-opened"
import moneyChange from "./money-change"
import moneyRecive from "./money-recive"
import moneySend from "./money-send"
import cubeRotate from "./cube-rotate"
import boldTruck from "./bold-truck"
import coin from "./coin"
import message from "./message"
import moneyBag from "./money-bag"
import truck from "./truck"
import truckIn from "./truck-in"
import truckOut from "./truck-out"
import chevronRightGreen from "./chevron-right-green"
import arrowRight from "./arrow-right"
import classification from "./classification"
import denomination from "./denomination"
import sealNumber from "./seal-number"

export const COLLECTION: Collection = {
    calendar,
    receiptEdit,
    walletMoney,
    logo,
    bank,
    chevronDown,
    notification,
    user,
    logout,
    settings,
    help,
    strongBox,
    globalRefresh,
    chevronRight,
    boldKey,
    region,
    reserveOffice,
    calenderBasic,
    country,
    dollar,
    add,
    trashcan,
    vaultOpened,
    moneyChange,
    moneyRecive,
    moneySend,
    cubeRotate,
    boldTruck,
    coin,
    message,
    moneyBag,
    truck,
    arrowRight,
    truckIn,
    truckOut,
    chevronRightGreen,
    classification,
    denomination,
    sealNumber,
}

function Icon(props: Props): JSX.Element {
    const { name = "" } = props

    const IconComponent =
        name && COLLECTION[name]
            ? COLLECTION[name]
            : () => <div style={{ height: "1rem", width: "1rem", backgroundColor: "transparent" }} />

    return <IconComponent {...props} />
}

export default Icon
