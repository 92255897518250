import { Props } from "./index.types"
import Card from "src/components/common/card"
import cx from "classnames"
import "./index.css"
import Icon from "src/assets/icons"

function CardVault(props: Props) {
    const { className = "", title = "", text = "", operation = "", icon = "", style = {} } = props
    return (
        <Card className={cx("vault-card__container", className)} style={style}>
            <div className="vault-card__icon-container">
                <Icon name={icon} className="vault-card__icon" />
            </div>
            <div className="vault-card__container-text">
                <p className="vault-card__title">{title}</p>
                <p style={{ fontWeight: 700 }}>{text}</p>
                {operation && <p className="vault-card__text-operation">{operation}</p>}
            </div>
        </Card>
    )
}

export default CardVault
