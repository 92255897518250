import { useNavigate } from "react-router-dom"
import Screen from "src/components/layout/screen"
import Button from "src/components/common/button"
import Table from "src/components/common/table"
import Collapse from "src/components/common/collapse"
import { baseColumns, completeColumns, openingData, getIcomingData, getOutgoingData } from "./index.data"
import "./index.css"

function CloseVault(): JSX.Element {
    const navigate = useNavigate()
    return (
        <Screen className="close-vault__screen">
            <div className="close-vault__head">
                <h1>ECI Vault Inventory</h1>
                <Button icon="strongBox" className="close-vault__button" onClick={() => navigate(-1)}>
                    Close Vault
                </Button>
            </div>
            <div className="close-vault__main">
                <Table
                    containerClassName="close-vault__table close-vault__mb"
                    className="close-vault__table__simple"
                    title="Opening balance for 10/10/2022"
                    columns={baseColumns}
                    data={openingData}
                    border={false}
                    rowHeader={false}
                />
                <Collapse title="Incoming" className="close-vault__collapse close-vault__mb">
                    <Table className="close-vault__table__complete" columns={completeColumns} data={getIcomingData()} />
                </Collapse>
                <Collapse title="Outgoing" className="close-vault__collapse close-vault__mb">
                    <Table
                        className="close-vault__table__complete"
                        columns={completeColumns}
                        data={getOutgoingData()}
                    />
                </Collapse>
                <Table
                    containerClassName="close-vault__table"
                    className="close-vault__table__simple"
                    title="Ending Inventory for 10/10/2022"
                    columns={baseColumns}
                    data={openingData}
                    border={false}
                    rowHeader={false}
                />
                <Button className="close-vault__button__secondary" type="secondary">
                    Settle Vault
                </Button>
                <p className="close-vault__note">ECI Vault can only be settled once a day.</p>
            </div>
        </Screen>
    )
}

export default CloseVault
