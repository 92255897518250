import { Props } from "./index.types"
import cx from "classnames"
import "./index.css"

function Card(props: Props) {
    const { children = null, className = "", style = {} } = props
    return (
        <div className={cx("card__container", className)} style={style}>
            {children}
        </div>
    )
}

export default Card
