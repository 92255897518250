import cx from "classnames"
import { useEffect, useState } from "react"
import { Props } from "./index.type"
import Icon from "src/assets/icons"
import "./index.css"

function Collapse(props: Props): JSX.Element {
    const { title = "", className = "", childrenClassName = "", children, defaultVisible = true } = props
    const [open, setOpen] = useState<boolean>(defaultVisible)

    useEffect(() => {
        setOpen(defaultVisible)
    }, [defaultVisible])

    return (
        <div className={cx("collapse", className)}>
            <div className="collapse__head" onClick={() => setOpen(!open)}>
                <div>
                    <p className="collapse__title">{title}</p>
                </div>
                <Icon
                    className="collapse__icon"
                    name="chevronRight"
                    style={{ transform: open ? "rotate(270deg)" : "rotate(90deg)" }}
                />
            </div>
            {open && <div className={childrenClassName}>{children}</div>}
        </div>
    )
}

export default Collapse
