import { memo, SVGProps } from "react"

const Help = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1em" height="1em" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.672 9.336A9.336 9.336 0 1 0 0 9.336a9.336 9.336 0 0 0 18.672 0ZM7.971 7.26a2.82 2.82 0 0 0-.06.354c0 .073-.033.094-.104.092H6.425c-.041 0-.075 0-.07-.06.003-.475.102-.944.29-1.38A2.55 2.55 0 0 1 7.881 5.03a3.617 3.617 0 0 1 2.82-.14 2.325 2.325 0 0 1 1.445 1.348c.153.405.207.84.159 1.27a2.125 2.125 0 0 1-.684 1.378c-.298.29-.62.56-.933.831a1.755 1.755 0 0 0-.56.71c-.076.22-.117.45-.124.683 0 .084-.013.166-.016.248 0 .043-.015.064-.062.064H8.688c-.04 0-.06-.011-.058-.055a5.005 5.005 0 0 1 .123-1.202 2.26 2.26 0 0 1 .68-1.06c.276-.256.55-.514.814-.785a1.6 1.6 0 0 0 .433-.818c.103-.524-.095-.913-.597-1.096a1.91 1.91 0 0 0-1.494.056c-.32.155-.548.45-.618.798Zm.527 5.878a.823.823 0 1 1 1.644.1.823.823 0 0 1-1.644-.1Z"
                fill="#fff"
            />
        </svg>
    )
})

export default Help
