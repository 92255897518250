import { useEffect, useState, useRef } from "react"
import cx from "classnames"
import Icon from "src/assets/icons"
import { Props, Option } from "./index.type"
import "./index.css"
import { useOutside } from "src/hooks/ui"

function Select(props: Props): JSX.Element {
    const { options = [], placeholder = "", value = "", onChange = null, icon = "", className = "", type = 1 } = props
    const [open, setOpen] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState<Option>({ label: "", value: "" })

    useEffect(() => {
        function getLabel(value = ""): any {
            let label = ""
            for (let index = 0; index < options.length; index++) {
                if (options[index].value === value) {
                    label = options[index].label
                    break
                }
            }
            return label
        }
        if (value !== currentValue.value) {
            const label = value ? getLabel(value) : ""
            setCurrentValue({ label, value })
        }
    }, [value])

    function handleSelect(params: Option): void {
        if (params.value !== currentValue.value) {
            setCurrentValue(params)
            if (onChange) onChange(params.value)
        }
    }

    const elementRef = useRef<HTMLDivElement>(null)
    useOutside(elementRef, () => setOpen(false))

    return (
        <div
            ref={elementRef}
            className={cx(type === 2 ? "select--header" : "select", className)}
            onClick={() => setOpen(!open)}
        >
            {icon && <Icon name={icon} className="select__icon" />}
            <span className={currentValue.label ? "select__label" : "select__label--placeholder"}>
                {currentValue.label ? currentValue.label : placeholder}
            </span>
            <Icon
                name="chevronDown"
                className="select__icon--chevron"
                style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
            />
            {open ? (
                <div className={`select__dropdown ${open ? "select__dropdown--animation" : ""}`}>
                    {options?.map(({ label = "", value = "" }, index) => (
                        <div
                            key={index}
                            className={"select__dropdown__item"}
                            onClick={() => handleSelect({ label, value })}
                        >
                            {label}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default Select
