import { memo, SVGProps } from "react"

const ClassificationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18.266 13.96c-.275 2.216-2.091 4.032-4.308 4.307a4.949 4.949 0 0 1-3.567-.916c-.558-.409-.425-1.275.242-1.475a7.905 7.905 0 0 0 5.25-5.25c.2-.659 1.067-.792 1.475-.242a5.016 5.016 0 0 1 .908 3.575Z"
            fill="#287248"
        />
        <path
            d="M8.325 1.666a6.661 6.661 0 0 0-6.658 6.658 6.661 6.661 0 0 0 6.658 6.659 6.661 6.661 0 0 0 6.659-6.659C14.975 4.65 12 1.666 8.325 1.666Zm-.783 5.725 2.008.7c.725.258 1.075.767 1.075 1.55 0 .9-.716 1.642-1.591 1.642h-.075v.041a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625v-.05c-.925-.041-1.667-.816-1.667-1.783a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625c0 .3.217.542.483.542h1.25c.192 0 .342-.175.342-.392 0-.292-.05-.308-.242-.375l-2.008-.7c-.717-.25-1.075-.758-1.075-1.55 0-.9.717-1.642 1.592-1.642h.075v-.033a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625v.05c.925.042 1.666.817 1.666 1.783A.63.63 0 0 1 10 7.8a.63.63 0 0 1-.625-.625c0-.3-.216-.541-.483-.541h-1.25c-.192 0-.342.175-.342.391-.008.284.042.3.242.367Z"
            fill="#287248"
        />
    </svg>
)

const ClassificationIconMemo = memo(ClassificationIcon)

export default ClassificationIconMemo
