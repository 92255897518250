import { memo, SVGProps } from "react"

const CalenderBasicIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={21} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.856 2.966v-1.3a.63.63 0 0 0-.625-.625.63.63 0 0 0-.625.625v1.25H8.189v-1.25a.63.63 0 0 0-.625-.625.63.63 0 0 0-.625.625v1.3c-2.25.208-3.341 1.55-3.508 3.542a.419.419 0 0 0 .417.441h14.1c.241 0 .441-.208.416-.441-.166-1.992-1.258-3.334-3.508-3.542ZM17.564 8.2H4.231a.836.836 0 0 0-.834.833v5.133c0 2.5 1.25 4.167 4.167 4.167h6.667c2.917 0 4.167-1.667 4.167-4.167V9.033a.836.836 0 0 0-.834-.834Zm-8.992 6.974c-.041.034-.083.075-.125.1a.626.626 0 0 1-.15.075.529.529 0 0 1-.15.05c-.058.009-.108.017-.166.017a.829.829 0 0 1-.317-.067.859.859 0 0 1-.275-.175.876.876 0 0 1-.242-.591c0-.217.092-.434.242-.592a.86.86 0 0 1 .275-.175.797.797 0 0 1 .633 0c.05.017.1.042.15.075l.125.1c.15.158.242.375.242.592a.876.876 0 0 1-.242.591Zm0-2.916a.876.876 0 0 1-.591.241.876.876 0 0 1-.592-.241.876.876 0 0 1-.242-.592c0-.217.092-.433.242-.592a.832.832 0 0 1 .908-.175c.109.042.2.1.275.175.15.159.242.375.242.592a.876.876 0 0 1-.242.592Zm2.917 2.916a.876.876 0 0 1-.591.242.876.876 0 0 1-.592-.242.876.876 0 0 1-.242-.591c0-.217.092-.434.242-.592.308-.308.875-.308 1.183 0 .15.158.242.375.242.592a.876.876 0 0 1-.242.591Zm0-2.916-.125.1a.634.634 0 0 1-.15.075.532.532 0 0 1-.15.05c-.058.008-.108.016-.166.016a.876.876 0 0 1-.592-.241.876.876 0 0 1-.242-.592c0-.217.092-.433.242-.592a.779.779 0 0 1 .275-.175.832.832 0 0 1 .908.175c.15.159.242.375.242.592a.876.876 0 0 1-.242.592Zm2.917 2.916a.876.876 0 0 1-.592.242.876.876 0 0 1-.591-.242.876.876 0 0 1-.242-.591c0-.217.091-.434.242-.592.308-.308.874-.308 1.183 0 .15.158.242.375.242.592a.876.876 0 0 1-.242.591Zm0-2.916-.125.1a.634.634 0 0 1-.15.075.532.532 0 0 1-.15.05 1.257 1.257 0 0 1-.167.016.876.876 0 0 1-.591-.241.876.876 0 0 1-.242-.592c0-.217.091-.433.242-.592a.86.86 0 0 1 .274-.175.797.797 0 0 1 .484-.05c.05.009.1.025.15.05.05.017.1.042.15.075l.125.1c.15.159.242.375.242.592a.876.876 0 0 1-.242.592Z"
            fill="#247346"
        />
    </svg>
)
const CalenderBasicIconMemo = memo(CalenderBasicIcon)

export default CalenderBasicIconMemo
