import { memo, SVGProps } from "react"

const MoneyBag = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={21} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.438 5.912a.44.44 0 0 0 .404.265h6.73a.44.44 0 0 0 .404-.265l1.93-4.412a.442.442 0 0 0-.511-.604l-3.288.821L10.519.13a.442.442 0 0 0-.624 0L8.306 1.717 5.02.896a.441.441 0 0 0-.511.604l1.93 4.412ZM7.113 24.71h6.19a6.89 6.89 0 0 0 6.889-6.89v-.002c0-1.63-.827-3.546-2.089-5.04l-4.82-5.717h-6.15l-4.821 5.716c-1.262 1.495-2.09 3.41-2.09 5.041v.001a6.89 6.89 0 0 0 6.89 6.892v-.001Zm.666-11.268A2.58 2.58 0 0 1 9.5 11.846v-.38a.706.706 0 0 1 1.412 0v.384a2.61 2.61 0 0 1 1.726 1.5.706.706 0 0 1-1.304.545 1.167 1.167 0 0 0-.257-.378 1.22 1.22 0 0 0-.87-.362h-.004a1.172 1.172 0 0 0-1.06 1.662c.131.283.365.498.658.605l1.293.468c.649.237 1.165.71 1.456 1.334.292.625.322 1.326.085 1.973a2.581 2.581 0 0 1-1.722 1.596v.38a.706.706 0 0 1-1.412 0v-.384a2.628 2.628 0 0 1-1.165-.668 2.676 2.676 0 0 1-.558-.825.707.707 0 0 1 1.298-.559c.062.146.15.275.26.385.234.234.543.369.874.362a1.17 1.17 0 0 0 .402-2.267l-1.293-.469a2.568 2.568 0 0 1-1.456-1.334 2.555 2.555 0 0 1-.085-1.972Z"
                fill="#287248"
            />
        </svg>
    )
})

export default MoneyBag
