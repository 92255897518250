import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Icon from "src/assets/icons"
import Button from "src/components/common/button"
import { Input } from "src/components/common/input"
import ModalBase from "src/components/common/modal"
import Table from "src/components/common/table"
import ContainerTop from "src/components/layout/containerTop"
import Screen from "src/components/layout/screen"
import CardVault from "src/components/sections/vault/card"
import Notification from "src/components/sections/vault/notification"
import "./index.css"
import { dataCards, notifications, columns, data } from "./index.data"

function OpenVault(): JSX.Element {
    const navigate = useNavigate()

    const [modalOpened, setModalOpened] = useState(false)
    const [sealNumber, setSealNumber] = useState("")
    const [errorNumber, setErrorNumber] = useState(false)

    useEffect(() => {
        if (modalOpened) {
            const seconds = 3000
            setTimeout(() => {
                navigate("/vault/close")
                setSealNumber("")
                setModalOpened(false)
            }, seconds)
        }
    }, [modalOpened])

    return (
        <Screen>
            <ContainerTop
                title="Currency Reserve Vault"
                subtitle="Enter the seal number to open the vault to register any ECI transactions"
            >
                <div className="open-vault__container-header">
                    <Input
                        placeholder="Enter seal number"
                        className="open-vault__input"
                        icon="boldKey"
                        value={sealNumber}
                        onChange={(value) => {
                            setSealNumber(value)
                            setErrorNumber(false)
                        }}
                        error={errorNumber}
                        type="number"
                    />
                    <Button
                        icon="strongBox"
                        className="open-vault__button"
                        onClick={() => (!sealNumber ? setErrorNumber(true) : setModalOpened(true))}
                    >
                        Open Vault
                    </Button>
                </div>
            </ContainerTop>
            <section className="open-vault__main">
                <div className="open-vault__container">
                    <div className="open-vault__container-left">
                        <div className="open-vault__cards">
                            {dataCards.map(({ icon, title, text, operation }, index) => (
                                <CardVault key={index} icon={icon} title={title} text={text} operation={operation} />
                            ))}
                        </div>
                        <Table
                            data={data}
                            columns={columns}
                            title="Vault Balance for October 4, 2022"
                            border={true}
                            editable={false}
                            containerClassName="open-vault__table"
                        />
                    </div>
                    <div className="open-vault__container-right">
                        <Notification notifications={notifications} className="open-vault__card-notification" />
                    </div>
                </div>
            </section>
            <ModalBase className="open-vault__modal" visible={modalOpened} onClose={() => setModalOpened(false)}>
                <div className="open-vault__modal__container">
                    <div className="open-vault__modal__icon">
                        <Icon name="vaultOpened" />
                    </div>
                    <h3>Vault Opened</h3>
                    <p className="open-vault__modal__text">
                        Don´t forget to close and settle the inventory for the day before you go. Have a nice day!
                    </p>
                </div>
            </ModalBase>
        </Screen>
    )
}

export default OpenVault
