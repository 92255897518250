import cx from "classnames"
import Icon from "src/assets/icons"
import { Props } from "./index.type"
import "./index.css"

function Link(props: Props): JSX.Element {
    const { className = "", text = "", icon = "", onClick = () => null } = props

    return (
        <div className={cx("link", className)} onClick={onClick}>
            {icon && <Icon name={icon} />}
            <span className="link__text">{text}</span>
        </div>
    )
}

export default Link
