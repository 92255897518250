import { memo, SVGProps } from "react"

const ChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={15} height={8} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m7.442 7.86-.63-.54L0 1.48 1.262 0l6.18 5.299 6.18-5.299 1.262 1.482L8.073 7.32l-.631.54Z"
            fill="#287248"
        />
    </svg>
)
const ChevronDownIconMemo = memo(ChevronDownIcon)

export default ChevronDownIconMemo
