import { memo, SVGProps } from "react"

const CountryIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.183 7.042C16.308 3.192 12.95 1.46 10 1.46h-.008c-2.942 0-6.309 1.725-7.184 5.575-.975 4.3 1.659 7.942 4.042 10.233A4.53 4.53 0 0 0 10 18.542a4.496 4.496 0 0 0 3.142-1.275c2.383-2.291 5.016-5.925 4.041-10.225ZM10 11.217a2.625 2.625 0 1 1 0-5.249 2.625 2.625 0 0 1 0 5.25Z"
            fill="#247346"
        />
    </svg>
)
const CountryIconMemo = memo(CountryIcon)

export default CountryIconMemo
