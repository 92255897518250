import { memo, SVGProps } from "react"

const DenominationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.975 5.534c-.358-1.809-1.7-2.6-3.567-2.6H5.091c-2.2 0-3.666 1.1-3.666 3.666v4.292c0 1.85.758 2.933 2.008 3.4.183.067.383.125.592.158.333.075.691.109 1.066.109h7.325c2.2 0 3.667-1.1 3.667-3.667V6.6c0-.391-.033-.741-.108-1.066ZM4.608 10a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625V7.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625V10Zm4.142.95c-1.217 0-2.2-.983-2.2-2.2 0-1.216.983-2.2 2.2-2.2 1.216 0 2.2.984 2.2 2.2 0 1.217-.984 2.2-2.2 2.2Zm5.383-.95a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625V7.5a.63.63 0 0 1 .625-.625.63.63 0 0 1 .625.625V10Z"
            fill="#287248"
        />
        <path
            d="M18.583 9.1v4.292c0 2.567-1.466 3.675-3.675 3.675H7.591c-.625 0-1.183-.091-1.666-.275a2.81 2.81 0 0 1-1.009-.616c-.15-.142-.033-.367.175-.367h7.317c3.084 0 4.917-1.833 4.917-4.908V6.6c0-.2.225-.325.367-.175.566.6.891 1.475.891 2.675Z"
            fill="#287248"
        />
    </svg>
)

const DenominationIconMemo = memo(DenominationIcon)

export default DenominationIconMemo
