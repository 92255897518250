import { memo, SVGProps } from "react"

const LogOutIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.8 2h-2.6C11 2 9 4 9 7.2v4.05h4.44l-2.07-2.07a.742.742 0 0 1-.22-.53c0-.19.07-.38.22-.53.29-.29.77-.29 1.06 0l3.35 3.35c.29.29.29.77 0 1.06l-3.35 3.35c-.29.29-.77.29-1.06 0a.754.754 0 0 1 0-1.06l2.07-2.07H9v4.05C9 20 11 22 14.2 22h2.59c3.2 0 5.2-2 5.2-5.2V7.2C22 4 20 2 16.8 2ZM2.75 11.25c-.41 0-.75.34-.75.75s.34.75.75.75H9v-1.5H2.75Z"
            fill="#2B8C55"
        />
    </svg>
)
const LogOutIconMemo = memo(LogOutIcon)

export default LogOutIconMemo
