export const baseColumns = [
    { header: "$100", accessor: "key100" },
    { header: "$50", accessor: "key50" },
    { header: "$20", accessor: "key20" },
    { header: "$5", accessor: "key5" },
    { header: "$2", accessor: "key2" },
    { header: "$1", accessor: "key1" },
    { header: "Total", accessor: "total" },
]

export const completeColumns = [{ header: "", accessor: "name" }, ...baseColumns]

export const openingData = [
    {
        key100: 999999999,
        key50: 999999999,
        key20: 999999999,
        key5: 999999999,
        key2: 999999999,
        key1: 999999999,
        total: 999999999,
    },
]

export const getIcomingData = () => {
    const labels = [
        "Deposits",
        "ReDeposits",
        "Shipments In",
        "Transfers In (Prepak)",
        "Transfers In (Processing)",
        "Total Incoming",
    ]

    return labels.map((name) => {
        return {
            name,
            ...openingData[0],
        }
    })
}

export const getOutgoingData = () => {
    const labels = ["Payments", "Shipments Out", "Transfers Out Prepak", "Transfers Out (Processing)", "Total Incoming"]

    return labels.map((name) => {
        return {
            name,
            ...openingData[0],
        }
    })
}
