export const columns = [
    { header: "", accessor: "name" },
    { header: "$100", accessor: "key100" },
    { header: "$50", accessor: "key50" },
    { header: "$20", accessor: "key20" },
    { header: "$5", accessor: "key5" },
    { header: "$2", accessor: "key2" },
    { header: "$1", accessor: "key1" },
    { header: "Total", accessor: "total" },
]

export const getData = (labels: string[]) => {
    return labels.map((name) => {
        return {
            name,
            key100: 0,
            key50: 0,
            key20: 0,
            key5: 0,
            key2: 0,
            key1: 0,
            total: 0,
        }
    })
}

export const getInventoryData = (labels: string[]) => {
    return labels.map((name) => {
        return {
            name,
            key100: 999999999,
            key50: 999999999,
            key20: 999999999,
            key5: 999999999,
            key2: 999999999,
            key1: 999999999,
            total: 999999999,
        }
    })
}
