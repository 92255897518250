import "./index.css"
import { Props } from "./index.types"
import Icon from "src/assets/icons"
import cx from "classnames"

function ModalBase(props: Props) {
    const { visible = false, children = null, close = false, onClose = () => null, className = "" } = props
    return (
        <>
            {visible && (
                <section className={visible ? `modal__container-main` : ""}>
                    <section className={cx("modal__container", className)}>
                        {close && (
                            <a onClick={onClose} className="modal__close">
                                <Icon name="close" />
                            </a>
                        )}
                        {children}
                    </section>
                </section>
            )}
        </>
    )
}

export default ModalBase
