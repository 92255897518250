export const countrys = [
    { value: "Afganistán", label: "Afganistán" },
    { value: "Albania", label: "Albania" },
    { value: "Argelia", label: "Argelia" },
    { value: "Andorra", label: "Andorra" },
    { value: "Angola", label: "Angola" },
    { value: "Anguila", label: "Anguila" },
    { value: "Antigua y Barbuda", label: "Antigua y Barbuda" },
    { value: "Argentina", label: "Argentina" },
    { value: "Armenia", label: "Armenia" },
    { value: "Aruba", label: "Aruba" },
    { value: "Australia", label: "Australia" },
    { value: "Austria", label: "Austria" },
    { value: "Azerbaiyán", label: "Azerbaiyán" },
    { value: "Bahamas", label: "Bahamas" },
    { value: "Bahrain", label: "Bahrain" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "Barbados", label: "Barbados" },
    { value: "Belarus", label: "Belarus" },
    { value: "Bélgica", label: "Bélgica" },
    { value: "Belice", label: "Belice" },
    { value: "Benín", label: "Benín" },
    { value: "Bermuda", label: "Bermuda" },
    { value: "Bután", label: "Bután" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Botsuana", label: "Botsuana" },
    { value: "Brasil", label: "Brasil" },
    { value: "Brunéi", label: "Brunéi" },
    { value: "Bulgaria", label: "Bulgaria" },
    { value: "Burkina Faso", label: "Burkina Faso" },
    { value: "Burundi", label: "Burundi" },
    { value: "Camboya", label: "Camboya" },
    { value: "Camerún", label: "Camerún" },
    { value: "Islas Caimán", label: "Islas Caimán" },
    { value: "Chad", label: "Chad" },
    { value: "Chile", label: "Chile" },
    { value: "China", label: "China" },
    { value: "Colombia", label: "Colombia" },
    { value: "Comoras", label: "Comoras" },
    { value: "Congo", label: "Congo" },
    { value: "Costa Rica", label: "Costa Rica" },
    { value: "Croacia", label: "Croacia" },
    { value: "Cuba", label: "Cuba" },
    { value: "Chipre", label: "Chipre" },
    { value: "República Checa", label: "República Checa" },
    { value: "Dinamarca", label: "Dinamarca" },
    { value: "Yibuti", label: "Yibuti" },
    { value: "Dominica", label: "Dominica" },
    { value: "República Dominicana", label: "República Dominicana" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Egipto", label: "Egipto" },
    { value: "El Salvador", label: "El Salvador" },
    { value: "Guinea Ecuatorial", label: "Guinea Ecuatorial" },
    { value: "Eritrea", label: "Eritrea" },
    { value: "Estonia", label: "Estonia" },
    { value: "Etiopía", label: "Etiopía" },
    { value: "Islas Malvinas", label: "Islas Malvinas" },
    { value: "Islas Feroe", label: "Islas Feroe" },
    { value: "Fiyi", label: "Fiyi" },
    { value: "Finlandia", label: "Finlandia" },
    { value: "Francia", label: "Francia" },
    { value: "Guyana Francesa", label: "Guyana Francesa" },
    { value: "Gabón", label: "Gabón" },
    { value: "Gambia", label: "Gambia" },
    { value: "Georgia", label: "Georgia" },
    { value: "Alemania", label: "Alemania" },
    { value: "Ghana", label: "Ghana" },
    { value: "Gibraltar", label: "Gibraltar" },
    { value: "Grecia", label: "Grecia" },
    { value: "Groenlandia", label: "Groenlandia" },
    { value: "Granada", label: "Granada" },
    { value: "Guatemala", label: "Guatemala" },
    { value: "Guernsey", label: "Guernsey" },
    { value: "Guinea", label: "Guinea" },
    { value: "Guinea-Bisáu", label: "Guinea-Bisáu" },
    { value: "Guyana", label: "Guyana" },
    { value: "Haití", label: "Haití" },
    { value: "Honduras", label: "Honduras" },
    { value: "Hong Kong", label: "Hong Kong" },
    { value: "Hungría", label: "Hungría" },
    { value: "Islandia", label: "Islandia" },
    { value: "India", label: "India" },
    { value: "Indonesia", label: "Indonesia" },
    { value: "Irán", label: "Irán" },
    { value: "Iraq", label: "Iraq" },
    { value: "Irlanda", label: "Irlanda" },
    { value: "Isla de Man", label: "Isla de Man" },
    { value: "Israel", label: "Israel" },
    { value: "Italia", label: "Italia" },
    { value: "Costa de Marfil", label: "Costa de Marfil" },
    { value: "Jamaica", label: "Jamaica" },
    { value: "Japón", label: "Japón" },
    { value: "Jersey", label: "Jersey" },
    { value: "Jordania", label: "Jordania" },
    { value: "Kazajistán", label: "Kazajistán" },
    { value: "Kenia", label: "Kenia" },
    { value: "Kiribati", label: "Kiribati" },
    { value: "Corea (Norte)", label: "Corea (Norte)" },
    { value: "Corea (Sur)", label: "Corea (Sur)" },
    { value: "Kuwait", label: "Kuwait" },
    { value: "Kirguizistán", label: "Kirguizistán" },
    { value: "Laos", label: "Laos" },
    { value: "Letonia", label: "Letonia" },
    { value: "Líbano", label: "Líbano" },
    { value: "Lesoto", label: "Lesoto" },
    { value: "Liberia", label: "Liberia" },
    { value: "Libia", label: "Libia" },
    { value: "Liechtenstein", label: "Liechtenstein" },
    { value: "Lituania", label: "Lituania" },
    { value: "Luxemburgo", label: "Luxemburgo" },
    { value: "Macau", label: "Macau" },
    { value: "Macedonia", label: "Macedonia" },
    { value: "Madagascar", label: "Madagascar" },
    { value: "Malaui", label: "Malaui" },
    { value: "Malasia", label: "Malasia" },
    { value: "Maldivas", label: "Maldivas" },
    { value: "Malí", label: "Malí" },
    { value: "Malta", label: "Malta" },
    { value: "Islas Marshall", label: "Islas Marshall" },
    { value: "Martinica", label: "Martinica" },
    { value: "Mauritania", label: "Mauritania" },
    { value: "Islas Mauricio", label: "Islas Mauricio" },
    { value: "México", label: "México" },
    { value: "Micronesia", label: "Micronesia" },
    { value: "Moldova", label: "Moldova" },
    { value: "Mónaco", label: "Mónaco" },
    { value: "Mongolia", label: "Mongolia" },
    { value: "Montenegro", label: "Montenegro" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Marruecos", label: "Marruecos" },
    { value: "Mozambique", label: "Mozambique" },
    { value: "Namibia", label: "Namibia" },
    { value: "Nauru", label: "Nauru" },
    { value: "Nepal", label: "Nepal" },
    { value: "Holanda", label: "Holanda" },
    { value: "Nueva Caledonia", label: "Nueva Caledonia" },
    { value: "Nueva Zelanda", label: "Nueva Zelanda" },
    { value: "Nicaragua", label: "Nicaragua" },
    { value: "Níger", label: "Níger" },
    { value: "Nigeria", label: "Nigeria" },
    { value: "Islas Marianas del Norte", label: "Islas Marianas del Norte" },
    { value: "Noruega", label: "Noruega" },
    { value: "Omán", label: "Omán" },
    { value: "Pakistán", label: "Pakistán" },
    { value: "Panamá", label: "Palaos" },
    { value: "Panamá", label: "Panamá" },
    { value: "Papúa Nueva Guinea", label: "Papúa Nueva Guinea" },
    { value: "Paraguay", label: "Paraguay" },
    { value: "Perú", label: "Perú" },
    { value: "Filipinas", label: "Filipinas" },
    { value: "Polonia", label: "Polonia" },
    { value: "Portugal", label: "Portugal" },
    { value: "Qatar", label: "Qatar" },
    { value: "Rumania", label: "Rumania" },
    { value: "Rusia", label: "Rusia" },
    { value: "Ruanda", label: "Ruanda" },
    { value: "Samoa", label: "Samoa" },
    { value: "San Marino", label: "San Marino" },
    { value: "Arabia Saudita", label: "Arabia Saudita" },
    { value: "Senegal", label: "Senegal" },
    { value: "Serbia", label: "Serbia" },
    { value: "Seychelles", label: "Seychelles" },
    { value: "Sierra Leona", label: "Sierra Leona" },
    { value: "Singapur", label: "Singapur" },
    { value: "Eslovaquia", label: "Eslovaquia" },
    { value: "Eslovenia", label: "Eslovenia" },
    { value: "Islas Salomón", label: "Islas Salomón" },
    { value: "Sudáfrica", label: "Sudáfrica" },
    { value: "Sudán Meridional", label: "Sudán Meridional" },
    { value: "España", label: "España" },
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "Santa Lucía", label: "Santa Lucía" },
    { value: "Sudán", label: "Sudán" },
    { value: "Suriname", label: "Suriname" },
    { value: "Suazilandia", label: "Suazilandia" },
    { value: "Suecia", label: "Suecia" },
    { value: "Suiza", label: "Suiza" },
    { value: "Siria", label: "Siria" },
    { value: "Taiwán", label: "Taiwán" },
    { value: "Tayikistán", label: "Tayikistán" },
    { value: "Tanzania", label: "Tanzania" },
    { value: "Tailandia", label: "Tailandia" },
    { value: "Togo", label: "Togo" },
    { value: "Tokelau", label: "Tokelau" },
    { value: "Tonga", label: "Tonga" },
    { value: "Trinidad y Tobago", label: "Trinidad y Tobago" },
    { value: "Túnez", label: "Túnez" },
    { value: "Turquía", label: "Turquía" },
    { value: "Turkmenistán", label: "Turkmenistán" },
    { value: "Islas Turcas y Caicos", label: "Islas Turcas y Caicos" },
    { value: "Tuvalu", label: "Tuvalu" },
    { value: "Uganda", label: "Uganda" },
    { value: "Emiratos Árabes Unidos", label: "Ucrania" },
    { value: "Emiratos Árabes Unidos", label: "Emiratos Árabes Unidos" },
    { value: "Reino Unido", label: "Reino Unido" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Uzbekistán", label: "Uzbekistán" },
    { value: "Vanuatu", label: "Vanuatu" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "Yemen", label: "Yemen" },
    { value: "Zambia", label: "Zambia" },
    { value: "Zimbabue", label: "Zimbabue" },
]

export function getCountrys(value: string) {
    const options = [{}]
    countrys.map((country) => {
        if (country.label.includes(value)) {
            options.push(country)
        }
    })
    options.shift()
    return options
}
