import cx from "classnames"
import { useState, useRef } from "react"
import { useOutside } from "src/hooks/ui"
import { Props } from "./index.type"
import "./index.css"
import Icon from "src/assets/icons"

function Dropdown(props: Props): JSX.Element {
    const { className = "", children, items = [], config = { top: 0, left: 0 }, dropStyle = {}, type = 1 } = props
    const [visible, setVisible] = useState<boolean>(false)

    const dropRef = useRef<HTMLDivElement>(null)
    const elementRef = useRef<HTMLDivElement>(null)
    const ref = elementRef.current

    useOutside(dropRef, () => setVisible(false))

    return (
        <div className="dropdown__container">
            <div ref={elementRef} onClick={() => setVisible(true)}>
                {children}
            </div>
            {visible && (
                <div
                    ref={dropRef}
                    className={cx("dropdown", visible ? "dropdown--animation" : "", className)}
                    style={{
                        top: Number(ref?.offsetTop) + Number(ref?.offsetHeight) + config.top + 20,
                        left:
                            Number(ref?.offsetLeft) -
                            (config.left > 0 ? config.left : Number(dropRef.current?.offsetWidth)),
                        ...dropStyle,
                    }}
                >
                    {items.map(({ label = "", secondary = "", onClick }, index) => (
                        <div
                            className="dropdown__item"
                            key={index}
                            onClick={() => {
                                setVisible(false)
                                onClick()
                            }}
                        >
                            <span className="drop__label">{label}</span>
                            <span className="drop__secondary">{secondary}</span>
                        </div>
                    ))}
                    {type === 2 && (
                        <div
                            className="dropdown__item--logout"
                            onClick={() => {
                                setVisible(false)
                                alert("Log out")
                            }}
                        >
                            <span className="drop__label">Log out</span>
                            <Icon name="logout" />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Dropdown
