import cx from "classnames"
import { Props } from "./index.type"
import "./index.css"

function Tabs({ className = "", style = {}, items = [], current = "", onChange }: Props): JSX.Element {
    const handleSelect = (value: string): void => onChange(value)

    return (
        <div className={cx("tabs", className)} style={style}>
            {items.map(({ label = "", value = "" }, index) => (
                <div
                    key={index}
                    className={cx({ tabs__item: true, "tabs__item--active": current === value })}
                    onClick={() => handleSelect(value)}
                >
                    <span className={"tabs__item__label"}>{label}</span>
                </div>
            ))}
        </div>
    )
}

export default Tabs
