import Table, { getDynamicData, getTotalData } from "src/components/common/table"
import Screen from "src/components/layout/screen"
import { Props } from "./index.types"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "src/components/common/button"
import { getMoneyFormat } from "src/utils/string"
import { columns, defaultValues, getData, keys } from "./index.data"
import FormItem from "./index.form"
import "./index.css"
import OutForProcessing from "../out-for-processing"

function Transfer({ dataInventory = [], columnsSummary = [], from = "", categories = [] }: Props): JSX.Element {
    const [values, setValues] = useState<any>(getData())
    const OUT_PROCESSING = "out-for-processing"
    const { control, getValues, watch, reset } = useForm({
        defaultValues,
    })

    useEffect(() => {
        let data = getValues()
        if (JSON.stringify(values) !== JSON.stringify(data)) {
            setValues(data)
        }
    }, [watch()])

    const getReadData = () => {
        if (values) {
            const result: any = []
            for (const denomination of keys) {
                const denominationObj: any = {
                    name: denomination,
                    amountFit: 0,
                    amountNew: 0,
                    amountProcessed: 0,
                    amountUnprocessed: 0,
                    total: 0,
                }
                for (const category in values) {
                    const coins = values[category]
                    const categoryCapitalized = category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()
                    if (coins.length > 0) {
                        for (const coin of coins) {
                            if (coin.key === denomination) {
                                denominationObj[`amount${categoryCapitalized}`] = coin.value
                                break
                            }
                        }
                    }
                    if (!denominationObj[`amount${categoryCapitalized}`]) {
                        denominationObj[`amount${categoryCapitalized}`] = 0
                    }
                }
                result.push(denominationObj)
            }
            result.push({
                name: "Total",
                amountFit: 0,
                amountNew: 0,
                amountProcessed: 0,
                amountUnprocessed: 0,
                total: 0,
            })
            return result
        }
    }
    const data: [] = getReadData()
    const dataSummary = getDynamicData(data, "total", { verticalTotals: true })

    console.log("dataSummary", dataSummary)
    const onSubmit = (): void => {
        getValues()
    }
    return (
        <Screen className="transfer-prepak__screen">
            <div className="transfer-prepak__layout">
                {from === OUT_PROCESSING ? (
                    <OutForProcessing />
                ) : (
                    <div className="transfer-prepak__container">
                        <form className="transfer-prepak__form">
                            <div className="transfer-prepak__form__container">
                                {categories.map(({ name = "" }, index) => (
                                    <FormItem key={index} name={name} control={control} />
                                ))}
                            </div>
                            <div className="transfer-prepak__card">
                                <Table
                                    containerClassName="transfer-prepak__container__table"
                                    title="Transfer Summary"
                                    className="transfer-prepak__table"
                                    columns={columnsSummary}
                                    data={dataSummary}
                                    border={false}
                                />
                                <Button
                                    className="transfer-prepak__button"
                                    onClick={onSubmit}
                                >{`Submit ${getMoneyFormat(getTotalData(dataSummary))}`}</Button>
                                <Button type={"link"} onClick={() => reset()}>
                                    Reset
                                </Button>
                            </div>
                        </form>
                    </div>
                )}

                <Table
                    containerClassName="transfer-prepak__table-inventory "
                    title="Vault inventory as for October 4, 2022"
                    columns={columns.map((item, index) => {
                        return { ...item, bold: index === 0 }
                    })}
                    data={dataInventory}
                />
            </div>
        </Screen>
    )
}

export default Transfer
