export const dataCards = [
    {
        id: "91827364",
        info: [
            {
                label: "Shiped from",
                value: "FRB - FRBNY",
            },
            {
                label: "Date Shipped",
                value: "October 14, 2022 16:23:45",
            },
            {
                label: "Carrier name",
                value: "Lorem Ipsum",
            },
            {
                label: "Bags",
                value: "3",
            },
            {
                label: "Total Dollar Amount",
                value: "$999,999,999",
            },
        ],
    },
    {
        id: "91827367",
        info: [
            {
                label: "Shiped from",
                value: "FRB - FRBNY",
            },
            {
                label: "Date Shipped",
                value: "October 14, 2022 16:23:45",
            },
            {
                label: "Carrier name",
                value: "Lorem Ipsum",
            },
            {
                label: "Bags",
                value: "3",
            },
            {
                label: "Total Dollar Amount",
                value: "$999,999,999",
            },
        ],
    },
    {
        id: "91126532",
        info: [
            {
                label: "Shiped from",
                value: "FRB - FRBNY",
            },
            {
                label: "Date Shipped",
                value: "October 14, 2022 16:23:45",
            },
            {
                label: "Carrier name",
                value: "Lorem Ipsum",
            },
            {
                label: "Bags",
                value: "3",
            },
            {
                label: "Total Dollar Amount",
                value: "$999,999,999",
            },
        ],
    },
    {
        id: "91019283",
        info: [
            {
                label: "Shiped from",
                value: "FRB - FRBNY",
            },
            {
                label: "Date Shipped",
                value: "October 14, 2022 16:23:45",
            },
            {
                label: "Carrier name",
                value: "Lorem Ipsum",
            },
            {
                label: "Bags",
                value: "3",
            },
            {
                label: "Total Dollar Amount",
                value: "$999,999,999",
            },
        ],
    },
]
