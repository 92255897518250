import { memo, SVGProps } from "react"

const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="m15.392 14.024.325 2.634c.083.691-.659 1.175-1.25.816l-2.884-1.716c-.2-.117-.25-.367-.141-.567a5.264 5.264 0 0 0 .641-2.5c0-3.05-2.616-5.533-5.833-5.533-.658 0-1.3.1-1.9.3a.42.42 0 0 1-.533-.5c.758-3.034 3.675-5.292 7.158-5.292 4.067 0 7.358 3.075 7.358 6.867 0 2.25-1.158 4.241-2.941 5.491Z"
            fill="#247346"
        />
        <path
            d="M10.833 12.691c0 .992-.366 1.909-.983 2.634-.825 1-2.133 1.642-3.6 1.642l-2.175 1.291c-.367.225-.833-.083-.783-.508l.208-1.642c-1.117-.775-1.833-2.017-1.833-3.417 0-1.466.783-2.758 1.983-3.524a4.736 4.736 0 0 1 2.6-.759c2.533 0 4.583 1.917 4.583 4.284Z"
            fill="#247346"
        />
    </svg>
)

const MessageIconMemo = memo(MessageIcon)
export default MessageIconMemo
