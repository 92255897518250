import cx from "classnames"
import { FieldProps } from "./index.types"
import "./index.css"

function Field({ className = "", style = {}, label = "", error = false, children }: FieldProps): JSX.Element {
    return (
        <div className={cx("field", className)} style={style}>
            <label className={cx({ field__label: true, "field__label--error": error })}>{label}</label>
            {children}
        </div>
    )
}

export default Field
