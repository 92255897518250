import { useFieldArray, Controller } from "react-hook-form"
import Collapse from "src/components/common/collapse"
import Icon from "src/assets/icons"
import { CurrencyInput } from "src/components/common/input"
import Select from "src/components/common/select"
import { options } from "./index.data"
import { Form } from "./index.types"
import Button from "src/components/common/button"
import "./index.css"

export function FormItem({ control, name = "" }: Form): JSX.Element {
    const { fields, append, remove } = useFieldArray({ control, name: name })

    return (
        <div className="transfer-prepak__collapse__container">
            <Collapse title={name} className="transfer-prepak__collapse">
                {fields.map((item, index) => (
                    <div key={item.id} className="transfer-prepak__form__row">
                        <div className="transfer-prepak__form__field1">
                            <Controller
                                control={control}
                                name={`${name}.${index}.key`}
                                render={({ field: { value, onChange } }) => (
                                    <Select
                                        className="transfer-prepak__form__select"
                                        icon="boldKey"
                                        placeholder="Denomination"
                                        options={options}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        <div className="transfer-prepak__form__field2">
                            <Controller
                                control={control}
                                name={`${name}.${index}.value`}
                                render={({ field: { value, onChange } }) => (
                                    <CurrencyInput
                                        className="transfer-prepak__form__input"
                                        icon="dollar"
                                        placeholder="Dollar amount"
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        {index !== 0 && (
                            <div className="transfer-prepak__form__touch" onClick={() => remove(index)}>
                                <Icon name={"trashcan"} />
                            </div>
                        )}
                    </div>
                ))}
                <Button
                    className="transfer-prepak__form__link"
                    type={"link"}
                    onClick={() => append({ key: "", value: 0 })}
                >
                    {"+ Add denomination"}
                </Button>
            </Collapse>
        </div>
    )
}

export default FormItem
