import "./index.css"
import Screen from "src/components/layout/screen"
import ContainerTop from "src/components/layout/containerTop"
import Card from "src/components/common/card"
import Icon from "src/assets/icons"
import { useNavigate } from "react-router-dom"

function Shipment(): JSX.Element {
    const navigate = useNavigate()
    const data_cards = [
        {
            icon: "truckIn",
            title: "Shipment In",
            subtitle: "Recieve In-transit Banknotes that were ordered from the New York Fed in to the ECI",
            route: "/shipment/in",
        },
        {
            icon: "truckOut",
            title: "Shipment Out",
            subtitle: "Processed Banknotes for the ECI´s inventory to be delivered to the NY Fed´s cash operation.",
            route: "/shipment/out",
        },
    ]

    return (
        <Screen>
            <ContainerTop title="Shipments" subtitle="Recieve and deliver Banknotes in and out the ECI inventory" />
            <section className="shipments">
                {data_cards.map(({ icon = "", title = "", subtitle = "", route = "" }, index) => (
                    <Card className="shipments__card" key={index}>
                        <div className="shipments__card__info">
                            <Icon name={icon} />
                            <span className="shipments__card__info--title">{title}</span>
                            <span className="shipments__card__info--subtitle">{subtitle}</span>
                        </div>
                        <div className="shipments__card__icon">
                            <Icon name="arrowRight" onClick={() => navigate(route)} />
                        </div>
                    </Card>
                ))}
            </section>
        </Screen>
    )
}

export default Shipment
