import cx from "classnames"
import { useEffect, useState, useRef } from "react"
import { Props, Option } from "./index.type"
import "./index.css"
import { countrys } from "./countrys"
import { Input } from "../input"
import { getCountrys } from "./countrys"
import { useOutside } from "src/hooks/ui"

function CountrySelect(props: Props): JSX.Element {
    const { placeholder = "", value = "", onChange = null, icon = "", className = "" } = props
    const [open, setOpen] = useState<boolean>(false)
    const [currentValue, setCurrentValue] = useState<Option>({ label: "", value: "" })
    const [valueInput, setValueInput] = useState<string>("")
    const [options, setOptions] = useState<any>(countrys)

    useEffect(() => {
        function getLabel(value = ""): any {
            let label = ""
            for (let index = 0; index < options.length; index++) {
                if (options[index].value === value) {
                    label = options[index].label
                    break
                }
            }
            return label
        }
        if (value !== currentValue.value) {
            const label = value ? getLabel(value) : ""
            setCurrentValue({ label, value })
        }
    }, [value])

    function handleSelect(params: Option): void {
        setOpen(false)
        if (params.value !== currentValue.value) {
            setCurrentValue(params)
            setValueInput(params.label)
            if (onChange) onChange(params.value)
        }
    }

    const elementRef = useRef<HTMLDivElement>(null)
    useOutside(elementRef, () => setOpen(false))

    function getOptions(value) {
        const newOptions = getCountrys(value)
        setOptions(newOptions)
        setOpen(true)
    }

    function handleChange(value: string) {
        if (onChange) onChange(value)
    }

    return (
        <div ref={elementRef} className={cx("select--country", className)}>
            <Input
                icon={icon}
                placeholder={placeholder}
                value={valueInput}
                onChange={(val) => {
                    setValueInput(val)
                    setCurrentValue({ label: val, value: val })
                    handleChange(val)
                    getOptions(val)
                }}
                className="select__input"
            />
            {open ? (
                <div className={`select__dropdown ${open ? "select__dropdown--animation" : ""}`}>
                    {options?.map(({ label = "", value = "" }, index) => (
                        <div
                            key={index}
                            className={"select__dropdown__item"}
                            onClick={() => handleSelect({ label, value })}
                        >
                            {label}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default CountrySelect
