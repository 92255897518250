import { memo, SVGProps } from "react"

const TruckIn = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={46} height={46} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={23} cy={23} r={23} fill="#48B050" fillOpacity={0.15} />
            <path
                d="M20.667 12.406v9.695c0 1.19.968 2.159 2.158 2.159H33.5a1.17 1.17 0 0 0 1.167-1.167v-7.455a4.302 4.302 0 0 0-4.305-4.305h-8.61c-.607 0-1.085.478-1.085 1.073Z"
                fill="#287248"
            />
            <path
                d="M11.917 27.083a.578.578 0 0 0-.584.583v1.167c0 1.937 1.564 3.5 3.5 3.5a3.51 3.51 0 0 1 3.5-3.5 3.51 3.51 0 0 1 3.5 3.5h2.334a3.51 3.51 0 0 1 3.5-3.5 3.51 3.51 0 0 1 3.5 3.5c1.936 0 3.5-1.563 3.5-3.5V26.5a1.17 1.17 0 0 0-1.167-1.167H22.417a2.918 2.918 0 0 1-2.917-2.917V16a1.17 1.17 0 0 0-1.167-1.167h-.98c-.84 0-1.61.455-2.03 1.178l-.746 1.307a.294.294 0 0 0 .256.432 2.918 2.918 0 0 1 2.917 2.916v3.5a2.918 2.918 0 0 1-2.917 2.917h-2.916Z"
                fill="#287248"
            />
            <path
                d="M27.667 34.667a2.333 2.333 0 1 1 0-4.667 2.333 2.333 0 0 1 0 4.667ZM18.333 34.667a2.333 2.333 0 1 1 0-4.667 2.333 2.333 0 0 1 0 4.667ZM11.333 23.618v1.715h3.5A1.17 1.17 0 0 0 16 24.167v-3.5a1.17 1.17 0 0 0-1.167-1.167h-1.505l-1.691 2.963c-.199.35-.304.747-.304 1.155Z"
                fill="#287248"
            />
            <path
                d="M26.747 20.667c.072 0 .144-.03.2-.094a.353.353 0 0 0 0-.453l-2.096-2.37 2.097-2.37a.353.353 0 0 0 0-.453.263.263 0 0 0-.402 0l-2.297 2.596a.353.353 0 0 0 0 .454l2.297 2.596a.265.265 0 0 0 .2.094Z"
                fill="#E4F3E5"
            />
            <path
                d="M24.514 18.07h6.37c.154 0 .283-.145.283-.32 0-.175-.129-.32-.284-.32h-6.369c-.155 0-.284.145-.284.32 0 .175.129.32.284.32Z"
                fill="#E4F3E5"
            />
        </svg>
    )
})

export default TruckIn
