import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import cx from "classnames"
import Drawer from "../drawer"
import Header from "src/components/common/header"
import { Props } from "./index.type"
import { Outlet } from "react-router-dom"
import "./index.css"

function Principal(props: Props): JSX.Element {
    const { className = "", style = {}, drawerItems = [] } = props

    const ref = useRef<HTMLElement>(null)
    const { pathname } = useLocation()

    const separator = pathname.split("/")
    const currentPath = separator.length >= 2 ? "/" + separator[1] : "/" + separator[1]

    useEffect(() => {
        if (Number(ref.current?.scrollTop) > 0) {
            ref.current?.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [pathname])

    return (
        <section className={cx("layout__container", className)} style={style}>
            <Header />
            <main className="layout__main">
                <section>
                    <Drawer items={drawerItems} current={currentPath} />
                </section>
                <section className="layout__page" ref={ref}>
                    <Outlet />
                </section>
            </main>
        </section>
    )
}

export default Principal
