import Icon from "src/assets/icons"
import cx from "classnames"
import { InputProps } from "./index.types"
import "./index.css"

function Input({
    className = "",
    style = {},
    value = "",
    type = "text",
    placeholder = "",
    onChange = undefined,
    icon = "",
    error = false,
    onBlur = undefined,
    onFocus = undefined,
}: InputProps): JSX.Element {
    function handleChange(value: string) {
        if (onChange) onChange(value)
    }

    return (
        <div
            className={cx({
                input: true,
                "input--state--error": error,
                [className]: className,
            })}
            style={style}
        >
            {icon && <Icon className="input__icon" name={icon} />}
            <input
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </div>
    )
}

export default Input
