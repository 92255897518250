import cx from "classnames"
import Icon from "src/assets/icons"
import { Props } from "./index.type"
import "./index.css"

function Button({
    className = "",
    style = {},
    children = "",
    type = "primary",
    icon = "",
    onClick = undefined,
    htmlType = "button",
    disabled = false,
}: Props): JSX.Element {
    return (
        <button
            className={cx({
                button: true,
                button__icon: icon && type !== "link",
                "button--primary": type === "primary",
                "button--secondary": type === "secondary",
                "button--link": type === "link",
                [className]: className,
            })}
            style={style}
            onClick={onClick}
            type={htmlType}
            disabled={disabled}
        >
            {icon && type !== "link" ? (
                <>
                    <Icon name={icon} />
                    <span>{children}</span>
                </>
            ) : (
                children
            )}
        </button>
    )
}

export default Button
