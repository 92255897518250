import Layout from "src/components/sections/payment"
import { getInventoryData, getData } from "src/components/sections/payment/index.data"

const labels = ["Fit", "New", "Processed", "Unprocessed", "Total"]

function Payment(): JSX.Element {
    return (
        <Layout
            title="Deposits"
            subtitle="Enter deposits to the ECI inventory. Only full bundles of Banknotes may be placed into the ECI inventory"
            tableTitle="Enter deposits to Inventory for October 4, 2022"
            buttonText="Deposits"
            data={getData(labels)}
            dataInventory={getInventoryData(labels)}
        />
    )
}

export default Payment
