import { memo, SVGProps } from "react"

const TruckIcon = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.334 3.407v9.695a2.16 2.16 0 0 1-2.158 2.159H3.5a1.17 1.17 0 0 1-1.167-1.167V6.639a4.302 4.302 0 0 1 4.305-4.305h8.61c.607 0 1.085.478 1.085 1.073Z"
                fill="#287248"
            />
            <path
                d="M25.084 18.084c.327 0 .583.257.583.583v1.167c0 1.937-1.563 3.5-3.5 3.5a3.51 3.51 0 0 0-3.5-3.5 3.51 3.51 0 0 0-3.5 3.5h-2.333a3.51 3.51 0 0 0-3.5-3.5 3.51 3.51 0 0 0-3.5 3.5 3.495 3.495 0 0 1-3.5-3.5v-2.333a1.17 1.17 0 0 1 1.167-1.167h11.083a2.918 2.918 0 0 0 2.917-2.917V7.001a1.17 1.17 0 0 1 1.166-1.167h.98c.84 0 1.61.455 2.03 1.178l.747 1.307a.294.294 0 0 1-.257.432 2.918 2.918 0 0 0-2.916 2.916v3.5a2.918 2.918 0 0 0 2.916 2.917h2.917Z"
                fill="#287248"
            />
            <path
                d="M9.333 25.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM18.667 25.667a2.333 2.333 0 1 0 0-4.667 2.333 2.333 0 0 0 0 4.667ZM25.667 14.618v1.715h-3.5A1.17 1.17 0 0 1 21 15.167v-3.5a1.17 1.17 0 0 1 1.167-1.167h1.505l1.691 2.963c.199.35.304.747.304 1.155Z"
                fill="#287248"
            />
            <path
                d="M10.254 11.665a.265.265 0 0 1-.2-.094.353.353 0 0 1 0-.453l2.096-2.37-2.097-2.37a.353.353 0 0 1 0-.453.263.263 0 0 1 .402 0l2.297 2.596c.11.125.11.33 0 .454l-2.297 2.596a.266.266 0 0 1-.201.094Z"
                fill="#E4F3E5"
            />
            <path
                d="M12.487 9.069h-6.37c-.154 0-.283-.145-.283-.32 0-.176.129-.322.284-.322h6.369c.155 0 .284.146.284.321 0 .176-.13.321-.284.321Z"
                fill="#E4F3E5"
            />
        </svg>
    )
})

export default TruckIcon
