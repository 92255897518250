import { memo, SVGProps } from "react"

const VaultOpened = (props: SVGProps<SVGSVGElement>) => (
    <svg width={82} height={82} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.605 71.727 8.17 77.01a6.503 6.503 0 0 0 6.202 4.63h4.535a6.501 6.501 0 0 0 6.202-4.63l1.426-4.82h1.52c.098.007.195.007.293 0l7.293 5.06c.161.11.338.196.523.258h.13c.184.054.374.083.567.086h10.634a2.148 2.148 0 0 0 2.148-2.148v-3.264h6.34L57.406 77a6.505 6.505 0 0 0 6.168 4.604h4.51a6.503 6.503 0 0 0 6.202-4.63l1.589-5.248A8.95 8.95 0 0 0 82 63.248V14.337a8.95 8.95 0 0 0-8.977-8.942H49.641V2.148A2.147 2.147 0 0 0 47.494 0H36.61c-.131.014-.26.04-.386.077h-.13c-.163.061-.32.142-.463.24l-7.276 5.095H9.457a8.95 8.95 0 0 0-8.934 8.933v48.878a8.95 8.95 0 0 0 6.082 8.504ZM20.96 75.79a2.183 2.183 0 0 1-2.053 1.52h-4.543a2.182 2.182 0 0 1-2.08-1.555l-1.108-3.608h10.85l-1.066 3.643ZM49.65 20.642h17.361v36.292H49.642l.008-36.292Zm18.478 56.694h-4.552a2.182 2.182 0 0 1-2.08-1.555l-1.065-3.608h10.875l-1.065 3.608a2.182 2.182 0 0 1-2.122 1.529l.009.026ZM73.025 9.69a4.648 4.648 0 0 1 4.638 4.639v48.877a4.648 4.648 0 0 1-4.638 4.64H49.642V61.23h19.55a2.15 2.15 0 0 0 2.147-2.148V18.468a2.147 2.147 0 0 0-2.147-2.148h-19.55V9.69h23.382Zm-27.72 63.567h-6.322V4.295h6.365l-.043 68.961Zm-10.618-1.941L15.472 57.983v-38.38l19.215-13.35v65.062ZM4.777 14.336a4.645 4.645 0 0 1 4.638-4.639H22.19L12.036 16.75a2.147 2.147 0 0 0-.86 1.718v40.614a2.145 2.145 0 0 0 .86 1.77l10.101 7.009H9.458a4.645 4.645 0 0 1-4.638-4.639l-.043-48.886Z"
            fill="#287248"
        />
        <path
            d="m24.266 48.198 3.582 1.778a2.15 2.15 0 1 0 1.915-3.849l-3.582-1.778a.962.962 0 0 1-.533-.859v-9.449c.001-.364.207-.696.533-.859l3.582-1.778a2.149 2.149 0 1 0-1.915-3.848l-3.582 1.778a5.228 5.228 0 0 0-2.92 4.707v9.45a5.224 5.224 0 0 0 2.92 4.706Z"
            fill="#287248"
        />
    </svg>
)

const VaultOpenedMemo = memo(VaultOpened)
export default VaultOpenedMemo
