type Config = {
    verticalTotals: boolean
    finalTotal?: boolean
}

export function getDynamicData<T extends object, Key extends keyof T>(
    source: T[],
    accesor: Key,
    config: Config = { verticalTotals: false, finalTotal: true }
): T[] {
    const data = source.map((item: T) => {
        let items = Object.values({ ...item, [accesor]: 0 })
        let acum = 0
        for (let i of items) {
            if (typeof i === "number") acum += i
        }
        return { ...item, [accesor]: acum }
    })

    if (config.verticalTotals) {
        const max = source.length - 1
        let item = source[max]
        const shorted = data.filter((_, index) => index !== max)
        let keys: string[] = Object.keys(item)
        let obj: any = {}
        for (let k = 0; k < keys.length; k++) {
            obj[keys[k]] = k === 0 ? Object.values(item)[k] : getCategorySum(shorted, keys[k])
        }

        data[max] = { ...obj }
        return data
    } else {
        return data
    }
}

function getCategorySum<T extends object>(source: T[], accesor: string) {
    let acum = 0
    for (let i of source) {
        acum += i[accesor] || 0
    }
    return acum
}

export function getTotalData<T extends object>(source: T[]): number {
    const item = Object.values(source[source.length - 1])
    const total: number = item[item.length - 1]
    return total
}

/*

V2

export function getDynamicData<T extends object, Key extends keyof T>(
    source: T[],
    accesor: Key,
    config: Config = { verticalTotals: false, finalTotal: true }
): T[] {
    return source.map((item: T, index) => {
        delete item[accesor]
        let items = Object.values(item)
        let acum = 0
        for (let i of items) {
            if (typeof i === "number") acum += i
        }

        if (config.verticalTotals && index === source.length - 1) {
            let keys = Object.keys(item)
            let obj: any = {}
            for (let k = 0; k < keys.length; k++) {
                obj[keys[k]] =
                    k === 0
                        ? items[k]
                        : k === keys.length && !config.finalTotal
                        ? undefined
                        : getCategorySum(source, keys[k])
            }
            return obj
        }

        return { ...item, [accesor]: acum }
    })
}


*/
