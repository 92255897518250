import { memo, SVGProps } from "react"

const BoldKey = memo((props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="1em" height="1em" fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16.492 3.518c-2.467-2.459-6.467-2.459-8.917 0C5.85 5.226 5.333 7.684 6 9.85l-3.917 3.917c-.275.283-.466.841-.408 1.241l.25 1.817c.092.6.65 1.167 1.25 1.25l1.817.25c.4.058.958-.125 1.241-.417l.684-.683a.413.413 0 0 0 0-.592L5.3 15.018a.629.629 0 0 1 0-.884.629.629 0 0 1 .883 0l1.625 1.625a.417.417 0 0 0 .584 0l1.766-1.758a6.312 6.312 0 0 0 6.334-1.558c2.458-2.459 2.458-6.467 0-8.925ZM12.083 10a2.084 2.084 0 1 1 .002-4.168A2.084 2.084 0 0 1 12.083 10Z"
                fill="#2B8C55"
            />
        </svg>
    )
})

export default BoldKey
