import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Layout from "src/components/layout/principal"
import Example from "src/components/sections/example"
import Payment from "./payment"
import Deposits from "./deposits"
import Redeposits from "./redeposits"
import { drawerItems } from "./menuData"
import CountryFlow from "src/components/sections/countryFlow"
import OpenVault from "./vault/open"
import DenominationSwitch from "./denomination"
import CloseVault from "./vault/close"
import Transfers from "./transfers"
import Shipment from "./shipment"
import TransfersPrepak from "./transfers/prepak"
import PrepakIn from "./transfers/prepak/in-for-prepak"
import PrepakOut from "./transfers/prepak/out-for-prepak"
import ProcessingIn from "./transfers/processing/in-for-processing"
import ProcessingOut from "./transfers/processing/out-for-processing"
import ShipmentIn from "./shipment/shipment-in"
import ShipmentOut from "./shipment/shipment-out"
import TransfersProcessing from "./transfers/processing"
import ShipmentDetail from "./shipment/detail"

function Router(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout drawerItems={drawerItems} />}>
                    <Route index element={<Navigate to="/vault" />} />
                    <Route path="/vault" element={<OpenVault />} />
                    <Route path="/vault/close" element={<CloseVault />} />
                    <Route path="/country" element={<CountryFlow />} />
                    <Route path="/example" element={<Example message="Hola ejemplo1" />} />
                    <Route path="/close-vault" element={<CloseVault />} />
                    <Route path="/denomination" element={<DenominationSwitch />} />
                    <Route path="/payments" element={<Payment />} />
                    <Route path="/deposits" element={<Deposits />} />
                    <Route path="/redeposits" element={<Redeposits />} />
                    <Route path="/shipment" element={<Shipment />} />
                    <Route path="/shipment/detail" element={<ShipmentDetail />} />
                    <Route path="/shipment/in" element={<ShipmentIn />} />
                    <Route path="/shipment/out" element={<ShipmentOut />} />
                    <Route path="/transfers" element={<Transfers />} />
                    <Route path="/transfers/transfers-prepak" element={<TransfersPrepak />}>
                        <Route index element={<Navigate to="/transfers/transfers-prepak/prepak-in" />} />
                        <Route path="/transfers/transfers-prepak/prepak-in" element={<PrepakIn />} />
                        <Route path="/transfers/transfers-prepak/prepak-out" element={<PrepakOut />} />
                    </Route>
                    <Route path="/transfers/transfers-processing" element={<TransfersProcessing />}>
                        <Route index element={<Navigate to="/transfers/transfers-processing/in" />} />
                        <Route path="/transfers/transfers-processing/in" element={<ProcessingIn />} />
                        <Route path="/transfers/transfers-processing/out" element={<ProcessingOut />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
