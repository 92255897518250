import { memo, SVGProps } from "react"

const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={30} height={41} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.364 19.357V1.85a1 1 0 0 1 1.548-.837l19.354 12.68a1 1 0 0 1 .452.837v18.174a1 1 0 0 1-1.567.823L8.796 20.18a1 1 0 0 1-.432-.823Z"
            fill="#48B050"
            stroke="#fff"
            strokeWidth={0.5}
        />
        <path
            d="M1 25.247V7.741a1 1 0 0 1 1.548-.836l19.355 12.68a1 1 0 0 1 .452.837v18.173a1 1 0 0 1-1.568.824L1.432 26.07A1 1 0 0 1 1 25.247Z"
            fill="#287248"
            stroke="#fff"
            strokeWidth={0.5}
        />
    </svg>
)
const LogoIconMemo = memo(LogoIcon)

export default LogoIconMemo
