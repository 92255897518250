import { memo, SVGProps } from "react"

const SealNumberIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.625 6.666v1.75c-.367-.05-.783-.075-1.25-.083V6.666c0-2.625-.742-4.375-4.375-4.375s-4.375 1.75-4.375 4.375v1.667c-.467.008-.883.033-1.25.083v-1.75c0-2.417.583-5.625 5.625-5.625s5.625 3.208 5.625 5.625Z"
            fill="#287248"
        />
        <path
            d="M15.625 8.417a10.82 10.82 0 0 0-1.25-.083h-8.75c-.466.008-.883.033-1.25.083-2.125.259-2.708 1.3-2.708 4.084v1.666c0 3.334.833 4.167 4.167 4.167h8.333c3.333 0 4.167-.833 4.167-4.167v-1.666c0-2.784-.584-3.825-2.709-4.084ZM7.26 13.926a.876.876 0 0 1-.592.241.83.83 0 0 1-.317-.066.86.86 0 0 1-.275-.175.876.876 0 0 1-.241-.592.83.83 0 0 1 .066-.317c.042-.1.1-.191.175-.275a.86.86 0 0 1 .275-.175.832.832 0 0 1 .909.175.963.963 0 0 1 .175.275.83.83 0 0 1 .066.317.876.876 0 0 1-.241.592Zm3.508-.275c-.042.1-.1.191-.175.275a.876.876 0 0 1-.592.241.86.86 0 0 1-.591-.241.963.963 0 0 1-.175-.275.83.83 0 0 1-.067-.317.86.86 0 0 1 .242-.592c.308-.308.866-.308 1.183 0a.86.86 0 0 1 .242.592.83.83 0 0 1-.067.317Zm3.158.275a.876.876 0 0 1-.591.241.876.876 0 0 1-.592-.241.86.86 0 0 1-.242-.592.86.86 0 0 1 .242-.592c.317-.308.875-.308 1.183 0 .034.042.067.084.1.134a.46.46 0 0 1 .075.141c.025.05.042.1.05.15.009.059.017.117.017.167a.876.876 0 0 1-.242.592Z"
            fill="#287248"
        />
    </svg>
)

const SealNumberIconMemo = memo(SealNumberIcon)

export default SealNumberIconMemo
