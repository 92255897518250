import "./index.css"
import cx from "classnames"
import { Props } from "./index.type"

function TextArea(props: Props): JSX.Element {
    const { placeholder = "", onChange = () => null, value = "", className = "", style = {} } = props

    const handleTextAreaChange = (e) => {
        if (onChange && typeof onChange === "function") onChange(e.target.value)
    }

    return (
        <div className={cx("textarea__container", className)} style={style}>
            <textarea
                autoComplete="off"
                className="textarea"
                placeholder={placeholder}
                onChange={handleTextAreaChange}
                value={value}
            />
        </div>
    )
}

export default TextArea
